import './CreditCardInEdit.css'
import { useFinancialContext } from '../../../../../../../FinancialContext';
import { useState, useEffect } from 'react';
import axios from 'axios';
import BankSelector from '../../AccountsCard/BankSelector';
import { showAlertBox } from '../../../../../../../staticData';
import AccountEdit from '../../AccountsCard/AccountEdit';
import useWindowWidth from '../../../../../../../useWindowWidth';

function CreditCardInEdit() {
    const { creditCardWithValue, updateDeleteCreateCreditCard, axiosURL, accountsData, handleCloseEdit, handleOpenEditView } = useFinancialContext();
    const isMobile = useWindowWidth();
    const [creatingNewCreditCard, setCreatingNewCreditCard] = useState(false);
    const [cardInEdit, setCardInEdit] = useState(null);

    const [creditCardInput, setCreditCardInput] = useState(null);

    const [newCreditCardBank, setNewCreditCardBank] = useState(0);
    const [newCreditCardName, setNewCreditCardName] = useState("");
    const [newCreditCardFechamento, setNewCreditCardFechamento] = useState("");
    const [newCreditCardVencimento, setNewCreditCardVencimento] = useState("");

    const valueInputter = (input, value) => {
        setCreditCardInput((prevValue) => ({
            ...prevValue,
            [input]: value
        }))
    }
    const handleFilterOnlyNumber = (input, e) => {
        let rawValue = e.target.value.replace(/\D/g, '');
        valueInputter(input, rawValue);
    }


    const fetchDeleteCreditCard = async () => {
        if (!cardInEdit) return;
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.delete(`${axiosURL}/creditCard/${cardInEdit.id}`, config);
            if (response.status === 200) {
                updateDeleteCreateCreditCard("delete", cardInEdit);
                showAlertBox('success', 'Cartão deletado com sucesso!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchCreateNewCreditCard = async () => {
        if (!newCreditCardName || !newCreditCardBank) {
            showAlertBox('error', 'Preencha todos os campos.');
            return;
        };
        if (newCreditCardFechamento >= 31 || newCreditCardVencimento >= 31) {
            showAlertBox('error', 'Dia inválido.');
            return;
        }
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const data = {
                nome_do_cartao: newCreditCardName,
                conta_cartao: newCreditCardBank.id,
                dia_do_fechamento_da_fatura: newCreditCardFechamento,
                dia_do_vencimento_da_fatura: newCreditCardVencimento
            }

            const response = await axios.post(`${axiosURL}/creditCard`, data, config);

            if (response.status === 201) {
                updateDeleteCreateCreditCard("create", response.data);
                handleClearAllData();
                showAlertBox('success', 'Cartão criado com sucesso!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchUpdateCreditCard = async () => {
        if (!cardInEdit || !creditCardInput.nome_do_cartao) {
            showAlertBox('error', 'Preencha todos os campos.');
            return;
        }

        if (creditCardInput.dia_do_fechamento_da_fatura >= 31 || creditCardInput.dia_do_vencimento_da_fatura >= 31) {
            showAlertBox('error', 'Dia inválido.');
            return;
        }

        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };


            const data = {
                id: cardInEdit.id,
                nome_cartao: creditCardInput.nome_do_cartao,
                dia_do_fechamento_da_fatura: creditCardInput.dia_do_fechamento_da_fatura,
                dia_do_vencimento_da_fatura: creditCardInput.dia_do_vencimento_da_fatura
            }
            const response = await axios.put(`${axiosURL}/creditCard`, data, config);
            if (response.status === 200) {
                updateDeleteCreateCreditCard("update", response.data);
                showAlertBox('success', 'Cartão alterado com sucesso!');
            }
        } catch (error) {
            console.log(error);
        }
    }



    const renderCreateNewCreditCard = () => {

        const userAccounts = accountsData.map(account => ({
            id: account.id,
            cor: account.banks.corBanco,
            nome: account.accountName,
            urlImagem: account.banks.urlImagem,
            type: account.banks.type
        }))

        const createNewAccount = {
            id: 0,
            cor: '#242424',
            nome: 'Nova conta',
            urlImagem: '/Images_Dashboard_Summary/IncomeSpendSummary/createNewAccount.svg'
        }

        userAccounts.push(createNewAccount);

        return (
            <>
                <div className='creating-new-credit-card-overlay' onClick={handleClearAllData} />
                <div className='create-new-credit-card-container'>
                    <header>
                        <p>Selecione a conta do cartão</p>
                    </header>
                    <div>
                        <BankSelector values={userAccounts} handleClickParent={handleAccountSelector} />
                    </div>
                    <footer>
                        <div>
                            <div>
                                <label>Nome do cartão</label>
                                <input
                                    type='text'
                                    maxLength={50}
                                    value={newCreditCardName}
                                    onChange={(e) => setNewCreditCardName(e.target.value)}
                                />
                            </div>
                            {newCreditCardBank && newCreditCardBank.type === 'BANK' ?
                                <div>
                                    <div>
                                        <label>Dia fechamento</label>
                                        <input
                                            type='text'
                                            inputMode="numeric"
                                            maxLength={2}
                                            value={newCreditCardFechamento}
                                            onChange={(e) => setNewCreditCardFechamento(e.target.value.replace(/\D/g, ''))}
                                        />
                                    </div>
                                    <div>
                                        <label>Dia vencimento</label>
                                        <input
                                            type='text'
                                            inputMode="numeric"
                                            maxLength={2}
                                            value={newCreditCardVencimento}
                                            onChange={(e) => setNewCreditCardVencimento(e.target.value.replace(/\D/g, ''))}
                                        />
                                    </div>
                                </div>
                            : null}
                        </div>
                        <div>
                            <p onClick={handleClearAllData}>Cancelar</p>
                            <button onClick={fetchCreateNewCreditCard}>Criar cartão</button>
                        </div>
                    </footer>
                </div>
            </>
        )
    }
    const handleStopPropagation = (e) => {
        e.stopPropagation()
    }
    const brazillianNumberFormatter = (value) => {
        if (!value) return '0,00';
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
    const handleClickCreditCard = (creditCard) => {
        if (cardInEdit && cardInEdit.id === creditCard.id) {
            setCardInEdit(null);
            return;
        }
        setCardInEdit(creditCard);

        const data = {
            dia_do_fechamento_da_fatura: creditCard.dia_do_fechamento_da_fatura,
            dia_do_vencimento_da_fatura: creditCard.dia_do_vencimento_da_fatura,
            nome_do_cartao: creditCard.nome_do_cartao,
            type: creditCard.type
        }
        setCreditCardInput(data);
    }
    const handleAccountSelector = (account) => {
        setNewCreditCardVencimento('')
        setNewCreditCardFechamento('');
        if (account.id === 0) {//criar nova conta
            handleCloseEdit();
            handleOpenEditView(<AccountEdit autoOpenCreateAcconut={true} />, "Suas contas já cadastradas");
            return;
        }
        setNewCreditCardBank(account);
        setNewCreditCardName('Cartão ' + account.nome)
    }
    const handleClearAllData = () => {
        setCreatingNewCreditCard(!creatingNewCreditCard);
        setNewCreditCardBank(null);
        setCreditCardInput("R$ 00,0")

        setNewCreditCardName("");
        setNewCreditCardFechamento("");
        setNewCreditCardVencimento("");
    }



    const renderEditCard = () => {
        return (
            <div className='creditcard-rendered-in-edit-selected'>
                <body>
                    <div>
                        <label>Nome do cartão</label>
                        <input
                            type='text'
                            onClick={handleStopPropagation}
                            maxLength={50}
                            value={creditCardInput.nome_do_cartao}
                            onChange={(e) => valueInputter("nome_do_cartao", e.target.value)}
                        />
                    </div>
                    {cardInEdit.conta.banks.type === 'BANK' &&
                        <div>
                            <div>
                                <label>Dia fechamento</label>
                                <input
                                    type='text'
                                    onClick={handleStopPropagation}
                                    onChange={(e) => handleFilterOnlyNumber("dia_do_fechamento_da_fatura", e)}
                                    maxLength={2}
                                    value={creditCardInput.dia_do_fechamento_da_fatura}
                                    inputMode="numeric"
                                />
                            </div>
                            <div>
                                <label>Dia vencimento</label>
                                <input
                                    type='text'
                                    onClick={handleStopPropagation}
                                    onChange={(e) => handleFilterOnlyNumber("dia_do_vencimento_da_fatura", e)}
                                    value={creditCardInput.dia_do_vencimento_da_fatura}
                                    maxLength={2}
                                    inputMode="numeric"
                                />
                            </div>
                        </div>
                    }
                </body>
                <footer>
                    <p onClick={fetchUpdateCreditCard}>Confirmar alteração</p>
                    <p onClick={fetchDeleteCreditCard}>Excluir cartão</p>
                </footer>
            </div>
        )
    }
    const renderCard = (creditCard) => {
        let subText;
        if (creditCard.dia_do_fechamento_da_fatura && creditCard.dia_do_vencimento_da_fatura) {
            subText = `Fechamento ${creditCard.dia_do_fechamento_da_fatura} | Vencimento ${creditCard.dia_do_vencimento_da_fatura}`;
        }
        let cardHeight = cardInEdit && cardInEdit.id === creditCard.id ? '10vw' : '5.5vw'
        if (isMobile) {
            cardHeight = cardInEdit && cardInEdit.id === creditCard.id ? '50vw' : '20vw'
        }
        return (
            <div
                className='creditcard-rendered-in-edit-view'
                style={{
                    minHeight: cardHeight,
                    backgroundColor: cardInEdit && cardInEdit.id === creditCard.id && `#24242408`,
                    cursor: (cardInEdit && cardInEdit.id === creditCard.id) ? 'default' : 'pointer'
                }}
                onClick={() => handleClickCreditCard(creditCard)}
            >
                <div>
                    <div>
                        <img src={creditCard.img} />
                        <div>
                            <p>{creditCard.nome_do_cartao}</p>
                            <p>{subText}</p>
                            <p>Cartão da conta <b style={{ color: creditCard.conta.banks.corBanco }}>{creditCard.conta.accountName}</b></p>
                        </div>
                    </div>
                    <p>R$ {brazillianNumberFormatter(creditCard.valor)}</p>
                </div>
                {(cardInEdit && cardInEdit.id === creditCard.id) && renderEditCard(creditCard)}
            </div>
        )
    }
    const renderFooter = () => {
        return (
            <footer>
                <button onClick={() => setCreatingNewCreditCard(!creatingNewCreditCard)}>Cadastrar novo cartão</button>
            </footer>
        )
    }

    return (
        <div className='edit-creditcard-container'>
            <body>
                {creditCardWithValue && Object.values(creditCardWithValue).map((creditCard) => renderCard(creditCard))}
            </body>
            {creatingNewCreditCard ? renderCreateNewCreditCard() : renderFooter()}
        </div>
    )
}

export default CreditCardInEdit