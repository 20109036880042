import './overlaySelection.css';
import { useState } from 'react';
import useWindowWidth from '../../../../useWindowWidth';

function OverlaySelection({ setOverlayOn, values, setOverLayComponent, color, maxHeight }) {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const isMobile = useWindowWidth();

    const renderValues = () => {
        return (
            values &&
            values.map((value, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: hoveredIndex === index && `${color}0D`,
                        transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    {value}
                </div>
            ))
        );
    };

    return (
        <>
            <div
                className="OpacityOverlay"
                onClick={() => {
                    setOverlayOn(false);
                    setOverLayComponent(null);
                }}
            />
            <div className="CreateNewFinanceWindow"
                style={{
                    height: (isMobile && maxHeight) && maxHeight
                }}
            >{values && renderValues()}</div>
        </>
    );
}

export default OverlaySelection;
