import './AccountsCard.css'
import EditButton from '../../../../../GenericComponents/EditButton'
import { useFinancialContext } from '../../../../../../FinancialContext'
import AccountEdit from './AccountEdit';

function AccountsCard() {
    const { accountsData, handleOpenEditView, hideFinances, totalBalance } = useFinancialContext();

    const handleClickEdit = (account) => {
        handleOpenEditView(<AccountEdit autoOpenAccount={account} />, "Suas contas já cadastradas");
    }

    const renderAccount = (account) => {
        let value;
        value = hideFinances ? "●●" : `R$ ${account.accountBalance.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

        let updateValue; 
        if(totalBalance.preferenciaSaldo === "Acumulado"){
            updateValue = <p onClick={() => handleClickEdit(account)}>Alterar saldo</p>
        }else{
            updateValue = <p style={{cursor: 'default'}}>Saldo no mês</p>
        }

        return (
            <div className='accountUserContainer'>
                <div>
                    <img src={account.banks.urlImagem} />
                    <div>
                        <p>{account.accountName}</p>
                        {updateValue}
                    </div>
                </div>
                <p>{value}</p>
            </div>
        )
    }

    return (
        <div className='FinancialCardSummaryContainer AccountContainerCard'>
            <header>
                <p>Minhas contas</p>
                <EditButton handleClick={handleClickEdit} />
            </header>
            <div />
            <body>
                {accountsData && Object.values(accountsData).map(account => renderAccount(account))}
            </body>
        </div>
    )
}

export default AccountsCard