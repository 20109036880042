import './EditWindow.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../useWindowWidth';


function EditWindow() {
    const { handleCloseEdit, openEditView, titleHeaderEdit, componentInEdit } = useFinancialContext();
    const isMobile = useWindowWidth();

    if (openEditView) {
        return (
            <>
                <div className='edit-window-overlay' onClick={handleCloseEdit} />
                <section className='edit-window-main-container'>
                    <header>
                        {isMobile && <img onClick={handleCloseEdit} src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/YellowArrow.svg'/>}
                        <p>{titleHeaderEdit}</p>
                    </header>
                    <body>
                        {componentInEdit}
                    </body>
                </section>
            </>
        )
    }
}

export default EditWindow;