import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { useFinancialContext } from "../../../../FinancialContext";
import { useNavigate } from "react-router-dom";

const clientId = '49129265076-po90vasdspu132r1oun11f04aghu4cem.apps.googleusercontent.com';

function GoogleLoginButton({ option, setErrorMessage }) {
  const { axiosURL, fetchMainData, setUserLogged } = useFinancialContext();
  const navigate = useNavigate();





  const fetchLogin = (token, email, name, img) => {

    const values = {
      token: token,
      email: email,
      fullName: name,
      profileImage:null
      //img: img - a imagem passada é em string, não dá para transformar em bite do jeito que tá
    }

    axios.post(`${axiosURL}/login/google`, values)
      .then(response => {
        if (response.status === 200 && response.data.token) {
          const tokenJwt = response.data.token;
          localStorage.setItem("SnnAcss", tokenJwt);
          setUserLogged(true);
          fetchMainData(tokenJwt);
          navigate('/dashboard');
          return;
        }
        setUserLogged(false);
      })
      .catch(error => {
      })
  }



  const fetchNewUser = (token, email, name, img) => {

    const values = {
      token: token,
      email: email,
      fullName: name,
      profileImage:null
      //img: img - a imagem passada é em string, não dá para transformar em bite do jeito que tá
    }
    axios.post(`${axiosURL}/users/create-new-user/google`, values)
      .then(response => {
        if (response.status === 201 && response.data.messageError === null) {
          const tokenJwt = response.data.token;
          localStorage.setItem("SnnAcss", tokenJwt);
          setUserLogged(true);
          fetchMainData(tokenJwt);
          navigate('/dashboard');
          return;
        }//tratar erro e fazer também que caso o usuário já tenha conta, e tente criar uma nova usando o google, ele só seja logado
        setUserLogged(false);
      })      
      .catch(error => {
      })

  }


  const onSuccess = (res) => {
    const tokenJwt = res.credential;

    const decoded = jwtDecode(tokenJwt);

    const userName = decoded.name;
    const userEmail = decoded.email;
    const userImage = decoded.picture;

    if (option === "LOGIN") {
      fetchLogin(tokenJwt, userEmail, userName, userImage)
    } else if (option === "NEW_USER") {
      fetchNewUser(tokenJwt, userEmail, userName, userImage)
    }
  };


  const onError = () => {
    console.log("Erro ao tentar login com Google");
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={onSuccess}
        onError={onError}
        theme="filled_black"
        shape="pill"
        size="icon"
      />
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginButton;
