import './EstatisticasView.css'
import HistoricoReceitasGastos from './EstatísticasCards/HistoricoReceitasGastos';
import PorcentagemCategoriasHistorico from './EstatísticasCards/PorcentagemCategoriasHistorico'
import { useFinancialContext } from '../../../../FinancialContext';
import CategoriesGraph from './EstatísticasCards/CategoriesGraphCard';


function EstatísticasView({targetDivRef}) {
    const { incomeData, spendData } = useFinancialContext();


    const CategoriesInMonth = () => {
        return (
            <>
                <p id='categories-in-month-title-estatistics'>Categorias do mês</p>
                <div className="categories-in-month-container" >
                    <div>
                        <CategoriesGraph
                            type={"income"}
                            viewClicked={null}
                            handleOpenEditView={null}
                        />
                    </div>
                    <div>
                        <CategoriesGraph
                            type={"spend"}
                            viewClicked={null}
                            handleOpenEditView={null}
                        />
                    </div>
                </div>
            </>
        )
    }

    const HistoryFinance = () => {

        return (
            <section>
                <p>Histórico das receitas e gastos</p>
                <div>
                    <HistoricoReceitasGastos />
                </div>
            </section>
        )
    }

    const HistoryCategories = () => {

        return (
            <section>
                <p>Participaçãos mensal por categoria</p>
                <div>
                    <div>
                        <PorcentagemCategoriasHistorico
                            incomeSpendData={incomeData}
                            type={"income"}
                        />
                    </div>
                    <div>
                        <PorcentagemCategoriasHistorico
                            incomeSpendData={spendData}
                            type={"spend"}
                        />
                    </div>
                </div>
                <div className='spaceSmartPhoneBottomBarSummary' />

            </section>
        )
    }

    return (
        <div
            className='estatisticsSectionContainer'
            ref={targetDivRef}
        >
            {CategoriesInMonth()}
            {HistoryFinance()}
            {HistoryCategories()}
        </div>
    )
}

export default EstatísticasView;