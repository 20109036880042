import './MainCategories.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import { useEffect, useState } from 'react';

function MainCategories() {
    const { spendData, spendCategoriesData, monthNumber, setSelectedView, hideFinances } = useFinancialContext();

    const [categorieValuesData, setCategorieValuesData] = useState({});

    const handleClickSeeMore = () => {
        setSelectedView("Estatísticas");
    }

    const brazillianPatternDouble = (value) => {
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const calculateCategories = () => {
        if (spendData && spendData.content.length > 0) {
            const groupsIdsIncomeSpend = spendData.content.map(element => element.categoria?.id || null);
            const groupsThatHaveIncomeSpend = spendCategoriesData.filter(group => groupsIdsIncomeSpend.includes(group.id));

            let categoriesArray = groupsThatHaveIncomeSpend.map((group) => ({
                id: group.id,
                name: group.nomeCategoria,
                value: spendData.content
                    .filter(incomeSpend => incomeSpend.categoria?.id === group.id)
                    .reduce((acc, current) => acc + current.valor, 0),

                color: group.categoriaCor ? group.categoriaCor : "#FFFFFF",
                categoriaIcone: group.categoriaIcone
            }));

            categoriesArray.sort((a, b) => b.value - a.value);
            setCategorieValuesData(categoriesArray);
            return;
        }else{
            setCategorieValuesData(null)
        }
    }

    const renderCategorie = (categorie) => {
        return (
            <div className='main-categorie-rendered'>
                <div>
                    <div style={{ backgroundColor: categorie.color }}>
                        <img src={categorie.categoriaIcone} />
                    </div>
                    <p>{categorie.name}</p>
                </div>
                <p>{hideFinances ? "●●" : `R$ ${brazillianPatternDouble(categorie.value)}`}</p>
            </div>
        )
    }

    useEffect(() => {
        calculateCategories();
    }, [spendData, spendCategoriesData, monthNumber])

    return (
        <div className='FinancialCardSummaryContainer main-categories-container'>
            <header>
                <p>Maiores gastos por categoria</p>
                <div onClick={handleClickSeeMore}>
                    <p>Ver mais</p>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/greyArrow.svg' />
                </div>
            </header>
            <body>
                {categorieValuesData && Object.values(categorieValuesData).map(categorie => renderCategorie(categorie))}
            </body>
        </div>
    )
}

export default MainCategories;