import './CreditCard.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import EditButton from '../../../../../GenericComponents/EditButton';
import CreditCardRendered from './CreditCardRendered';
import CreditCardInEdit from './CreditCardInEdit';

function CreditCardCard({openFullCard}) {
    const { handleOpenEditView, creditCardWithValue } = useFinancialContext();

    const handleClick = () => {
        handleOpenEditView(<CreditCardInEdit/>, "Seus cartões já criados");
    }

    return (
        <div className='FinancialCardSummaryContainer creditCardContainer'>
            <header>
                <p>Cartões cadastrados</p>
                <EditButton handleClick={handleClick} />
            </header>
            <div />
            <body>
                {creditCardWithValue && Object.values(creditCardWithValue).map((creditCard) => <CreditCardRendered creditCard={creditCard} openFullCard={openFullCard}/>)}
            </body>
        </div>
    )
}

export default CreditCardCard;