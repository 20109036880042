import './FormsCreateNewUser.css'
import InputLoginSingIn from '../InputLoginSingIn';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { showAlertBox } from '../../../staticData'
import { useFinancialContext } from '../../../FinancialContext';
import GoogleLoginButton from './LoginGoogleButton';

function FormsCreateNewUser() {
    const { axiosURL, setUserLogged, fetchMainData } = useFinancialContext();
    const navigate = useNavigate();
    const [inputClicked, setInputClicked] = useState(null); //UseState que grava qual input foi cliado, para alterar o CSS dele

    //Objeto que grava as informações de cada input
    const [inputValues, setInputValues] = useState({
        name: {
            key: 'name',
            inputType: 'text',
            textPlaceholder: 'Digite o seu nome completo',
            moveTitle: 'Nome',
            textAlert: 'Use no máximo 70 caracteres',
            errorMessage: '',
            value: '',
            size: 70,
            inputMode: 'text'
        },
        email: {
            key: 'email',
            inputType: 'email',
            textPlaceholder: 'Informe o seu principal e-mail',
            moveTitle: 'E-mail',
            textAlert: 'Prometemos só enviar o que realmente importa. Sem Spam! ',
            errorMessage: '',
            value: '',
            size: 50,
            inputMode: 'text'
        },
        password: {
            key: 'password',
            inputType: 'password',
            textPlaceholder: 'Digite a sua senha',
            moveTitle: 'Senha',
            textAlert: 'A senha deve ter no mínimo 8 caracteres, com letras, números e símbolos',
            errorMessage: '',
            value: '',
            size: 50,
            inputMode: 'text'
        },
        confirmPassword: {
            key: 'confirmPassword',
            inputType: 'password',
            textPlaceholder: 'As senhas precisam ser iguais',
            moveTitle: 'Confirmação de senha',
            textAlert: 'Confirme a sua senha para criar sua conta',
            errorMessage: '',
            value: '',
            size: 50,
            inputMode: 'text'
        }
    })

    //Função que atualiza os inputs mais genéricos, que não precisam de caracteres especiais, como o nome completo.
    const updateValueInput = (value, inputKey) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [inputKey]: {
                ...prevValues[inputKey],
                errorMessage: '',
                value: value
            }
        }));
    }
    //Função que coloca uma mensagem de erro no input. É necessário passar a mesagem de erro e qual input quer colocar a mensagem de erro como string
    const setErrorMessageInput = (errorMessage, inputKey) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [inputKey]: {
                ...prevValues[inputKey],
                errorMessage: errorMessage
            }
        }))
    }
    //Função que verifica se a senha possui letras, números e caracteres especiais e que vê se a confirmPassword bate com a password
    const handleVerifyPassword = () => {
        const password = inputValues.password.value;
        const confirmPassword = inputValues.confirmPassword.value;

        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const samePassword = password === confirmPassword;

        if (!hasLetter || !hasNumber || !hasSpecialCaracter || password.length < 8) {
            setErrorMessageInput("A senha precisa ter pelo menos uma letra, um número, um caractere especial e pelo menos 8 caracteres.", 'password')
            showAlertBox('error', "Alguns campos não estão validados.");

            return false;
        }

        if (password !== confirmPassword) {
            setErrorMessageInput("As senhas não coincidem!", 'confirmPassword')
            showAlertBox('error', "Alguns campos não estão validados.");

            return false;
        }

        return true;

    }
    //Função que encaminha a atualização do input para a função certa. Se for o input de CPF ele direciona para a função que atualiza o input de acordo com o padrão do CPF.
    const handleInputChange = (value, inputKey) => {
        updateValueInput(value, inputKey);
    };



    //Função guarda chuva, que chama as funções para fazer as devidas verificações e por fim, chama a função que realiza a requisição.
    const handleCreateAccount = () => {
        let inputBlank = false;
        Object.values(inputValues).forEach((input) => {
            if (!input.value) {
                inputBlank = true;
                setInputValues(prevValues => ({
                    ...prevValues,
                    [input.key]: {
                        ...prevValues[input.key],
                        errorMessage: 'Para criar a conta é obrigatório preencher este campo!'
                    }
                }))
            }
        })
        if (inputBlank) return;
        if (!handleVerifyPassword()) return;
        fetchCreateNewAccount();
    }
    //Função que faz a requisição de criar a conta
    const fetchCreateNewAccount = async () => {
        const values = {
            fullName: inputValues.name.value,
            email: inputValues.email.value,
            password: inputValues.password.value
        };


        axios.post(`${axiosURL}/users/create-new-user`, values)
            .then(response => {
                const teste = response.data.messageError;
                if (response.status === 201 && response.data.messageError === null) {
                    const tokenJwt = response.data.token;
                    localStorage.setItem("SnnAcss", tokenJwt);
                    setUserLogged(true);
                    fetchMainData(tokenJwt);
                    navigate('/dashboard');
                }

                else if (response.data.messageError != null || response.status === 403) {
                    if (teste === "E-mail já cadastrado!") {
                        showAlertBox('error', "Já existe uma conta usando o e-mail informado.");
                    }
                } else {
                    throw new Error('Falha ao efetuar login');
                }
            })
    }




    return (
        <div className='mainContainerFormsCreateNewUser' onKeyDown={(e) => { if (e.key === 'Enter') handleCreateAccount(e) }}>
            <body>
                {Object.values(inputValues).map((inputCamp, index) => (
                    <InputLoginSingIn
                        key={index}
                        inputSize={inputCamp.size}
                        inputKey={inputCamp.key}
                        inputType={inputCamp.inputType}
                        textPlaceholder={inputCamp.textPlaceholder}
                        moveTitle={inputCamp.moveTitle}
                        textAlert={inputCamp.textAlert}
                        errorMessage={inputCamp.errorMessage}
                        setInputClicked={setInputClicked}
                        inputClicked={inputClicked}
                        valueInputed={inputCamp.value}
                        valueSetter={(value, key) => handleInputChange(value, key)}
                        inputMode={inputCamp.inputMode}
                    />
                ))}
            </body>
            <footer>
                <GoogleLoginButton option={"NEW_USER"}/>
                <p>Ou</p>
                <button onClick={handleCreateAccount}>Criar conta</button>
            </footer>
        </div>
    )
}

export default FormsCreateNewUser;
