import './HeaderOpenCardView.css'
import Dropbox from '../Dropbox'
import { useState, useEffect } from 'react';
import useWindowWidth from '../../../../../../../useWindowWidth';
import { parseISO } from 'date-fns';
import { useFinancialContext } from '../../../../../../../FinancialContext';

function HeaderOpenCardView({ financeTotal, type, categoriesRawData, closeFilterWindows, setCloseFilterWindows,
    setFinancialData, financialRawData, setClickedFinance, clickedFinance, accountRawData, monthName, closeFullView, creditCardRawData }) {

    const { setDirectCreditCardFilterBySummary, directCreditCardFilterBySummary } = useFinancialContext();

    const isMobile = useWindowWidth();
    const notSelectedAccount = {
        id: 0,
        name: 'Contas',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AccountFilterIcon.svg',
        color: '#3E6BBD'
    }
    const notSelectedPaid = {
        id: 0,
        name: `${type === 'income' ? 'Recebidas' : 'Pagas'}`,
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllPaidFilterIcon.svg',
        color: '#3EBD80'
    };
    const notSelectedCategorie = {
        id: 0,
        name: 'Categorias',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg',
        color: '#3EBDB6'
    }
    const notSelectedOrigin = {
        id: 0,
        name: `${type === 'income' ? 'Origem' : 'Pagamento'}`,
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg',
        color: '#3EBD80'
    }
    const notOrdered = {
        id: 0,
        name: 'Ordenar',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllOrderIcon.svg',
        color: '#387BA1'
    }


    //solução para mobile visto que o css não está funcionando
    const categorieAndImageSelectedStyle = isMobile ? {
        minWidth: '4.5vw',
        minHeight: '4.5vw',
        maxWidth: '4.5vw',
        maxHeight: '4.5vw'
    } : {}

    const fontSizeMobileSelectedStyle = isMobile ? {
        fontSize: '3.5vw'
    } : {}



    const [selectedAccount, setSelectedAccount] = useState(notSelectedAccount);
    const [paidFilterName, setPaidFilterName] = useState(notSelectedPaid);
    const [selectedCategorie, setSelectedCategorie] = useState(notSelectedCategorie);
    const [selectedOrigin, setSelectedOrigin] = useState(notSelectedOrigin);
    const [orderFinances, setOrderFinances] = useState(notOrdered);
    const [selectedCreditCard, setSelectedCreditCard] = useState(null);

    const [filterActive, setFilterActive] = useState(false);
    const [categoriesData, setCategoriesData] = useState(null);
    const [accountsData, setAccountsData] = useState(null);
    const [originData, setOriginData] = useState(null);

    const clearFilters = () => {
        setFilterActive(false);
        setPaidFilterName(notSelectedPaid);
        setOrderFinances(notOrdered);
        setSelectedCategorie(notSelectedCategorie);
        setFinancialData(financialRawData);
        setClickedFinance(null);
        setSelectedAccount(notSelectedAccount);
        setSelectedOrigin(notSelectedOrigin);
        setSelectedCreditCard(null);
        setDirectCreditCardFilterBySummary(null);
    }
    const applyFilter = (account, paid, categorie, order, origin, creditCard) => {
        const accountAux = account?.id === 0 ? null : account;
        const paidAux = paid?.id === 0 ? null : paid;
        const originFilterDeactive = !origin || origin?.id === 0 || origin.value === 'CARTAO_DE_CREDITO';

        if (!accountAux && !paid && !categorie && !order) {
            setFinancialData(financialRawData);
            return;
        }

        const accountFilter = accountAux !== null ? accountAux.id : null;
        const paidFilter = paidAux?.id === 1;
        const categorieFilter = categorie !== null ? categorie.id : null;

        let filteredData = financialRawData.content.map(fin => {

            if ((!categorieFilter || fin.categoria?.id === categorieFilter)
                && (!paidAux || fin.pagaOuRecebida === paidFilter)
                && (!accountAux || fin.account?.id === accountFilter)
                && (!creditCard || fin.creditCard?.id === creditCard.id)
                && (originFilterDeactive || fin.origemFinanca === origin.value)
            ) {
                return fin;
            }
        }).filter(finance => finance !== null && finance !== undefined && finance);

        if (order && order.id === 1) {
            filteredData = filteredData.sort((a, b) => b.valor - a.valor);
        }
        if (order && order.id == 2) {
            filteredData = filteredData.sort((a, b) => a.valor - b.valor);
        }
        if (order && order.id == 3) {
            filteredData = filteredData.sort((a, b) => parseISO(a.data).getDate() - parseISO(b.data).getDate());
        }

        if (clickedFinance && order) {
            const index = filteredData.findIndex(fin => fin.id === clickedFinance.id);
            const [clicked] = filteredData.splice(index, 1);
            filteredData.unshift(clicked)
        }

        setFinancialData((prevData) => ({
            ...prevData,
            content: filteredData
        }));
    }





    //Account
    useEffect(() => {
        let userAccountData = [
            {
                name: (
                    <div>
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AccountFilterIcon.svg'
                        />
                        <p>Todas as contas</p>
                    </div>
                ),
                id: 0,
            },
        ];

        const mappedAccounts = accountRawData.map((account) => {
            const logo = account.banks.urlImagem;

            let line = (
                <div>
                    <img
                        src={logo}
                        alt="Bank Logo"
                        style={categorieAndImageSelectedStyle}
                    />
                    <p style={fontSizeMobileSelectedStyle}>{account.accountName}</p>
                </div>
            );

            return {
                name: line,
                id: account.id,
                bank: account.banks,
                color: account.banks.corBanco
            };
        });

        userAccountData = userAccountData.concat(mappedAccounts);
        setAccountsData(userAccountData);
    }, [accountRawData, isMobile]);
    const handleClickAccount = (item) => {
        if (item?.id === 0) {
            setSelectedAccount(notSelectedAccount);
            applyFilter(null, paidFilterName, selectedCategorie, orderFinances, selectedOrigin, selectedCreditCard);
            return
        }
        setSelectedAccount(item);
        setFilterActive(true);
        applyFilter(item, paidFilterName, selectedCategorie, orderFinances, selectedOrigin, selectedCreditCard);
    }





    /*paymentMethod*/
    useEffect(() => {
        let useOriginMethod = [
            {
                name: (
                    <div>
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg'
                        />
                        <p>{type === 'income' ? 'Todas as origens' : 'Todos os métodos'}</p>
                    </div>
                ),
                id: 0,
                color: "#08BFB1"
            },
            {
                name: (
                    <div >
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/pixIcon.svg'
                            style={categorieAndImageSelectedStyle}
                        />
                        <p style={fontSizeMobileSelectedStyle}>Pix</p>
                    </div>
                ),
                id: -1,
                color: "#08BFB1",
                value: 'PIX'
            },
            {
                name: (
                    <div>
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/transferIcon.svg'
                            style={categorieAndImageSelectedStyle}
                        />
                        <p style={fontSizeMobileSelectedStyle}>Transferência</p>
                    </div>
                ),
                id: -2,
                color: "#0848BF",
                value: 'TRANSFERENCIA'
            },
            {
                name: (
                    <div>
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/boletoIcon.svg'
                            style={categorieAndImageSelectedStyle}
                        />
                        <p style={fontSizeMobileSelectedStyle}>Boleto</p>
                    </div>
                ),
                id: -3,
                color: "#242424",
                value: 'BOLETO'
            }
        ];

        if (type === 'spend') {
            const debit = {
                name: (
                    <div>
                        <img
                            src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/debitCardIcon.svg'
                            style={categorieAndImageSelectedStyle}
                        />
                        <p style={fontSizeMobileSelectedStyle}>Cartão de débito</p>
                    </div>
                ),
                id: -4,
                color: "#843031",
                value: 'CARTAO_DE_DEBITO'
            }
            useOriginMethod = useOriginMethod.concat(debit)

            const mappedOrigin = creditCardRawData.content.map(credit => {

                const logo = credit.account.banks.urlImagem;

                let line = (
                    <div>
                        <img
                            src={logo}
                            style={categorieAndImageSelectedStyle}
                        />
                        <p style={fontSizeMobileSelectedStyle}>{credit.nome_cartao}</p>
                    </div>
                );
                return {
                    name: line,
                    id: credit.id,
                    color: credit.account.banks.corBanco,
                    value: 'CARTAO_DE_CREDITO'
                };
            }).filter(credit => credit !== null);

            useOriginMethod = useOriginMethod.concat(mappedOrigin)
        }

        setOriginData(useOriginMethod);
    }, [creditCardRawData, isMobile])
    const handleClickOrigin = (item) => {

        switch (item.id) {
            case 0:
                setSelectedOrigin(notSelectedOrigin);
                setSelectedCreditCard(null);
                setFilterActive(true);
                applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, null, null);
                return;
            case -1:
                setSelectedOrigin(item);
                setSelectedCreditCard(null);
                setFilterActive(true);
                applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, item, null);
                return;
            case -2:
                setSelectedOrigin(item);
                setSelectedCreditCard(null);
                setFilterActive(true);
                applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, item, null);
                return;
            case -3:
                setSelectedOrigin(item);
                setSelectedCreditCard(null);
                setFilterActive(true);
                applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, item, null);
                return;
            case -4:
                setSelectedOrigin(item);
                setSelectedCreditCard(null);
                setFilterActive(true);
                applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, item, null);
                return;
        }
        setFilterActive(true);
        setSelectedOrigin(item);
        setSelectedCreditCard(item);
        applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, item, item); //creditCard

        //cartao
    }
    useEffect(() => {
        //Funçao para filtrar direto o cartão caso o usuário clique em "Acessar fatura"
        if (directCreditCardFilterBySummary) {

            let line = (
                <div>
                    <img
                        src={directCreditCardFilterBySummary.img}
                        style={categorieAndImageSelectedStyle}
                    />
                    <p style={fontSizeMobileSelectedStyle}>{directCreditCardFilterBySummary.nome_do_cartao}</p>
                </div>
            );


            const creditCard = {
                name: line,
                id: directCreditCardFilterBySummary.id,
                color: directCreditCardFilterBySummary.conta.banks.corBanco,
                value: 'CARTAO_DE_CREDITO'
            }

            setFilterActive(true);
            setSelectedOrigin(creditCard);
            setSelectedCreditCard(creditCard);
            applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, creditCard, creditCard); //creditCard
        }
    }, [directCreditCardFilterBySummary])







    //Categories
    useEffect(() => {

        let userCategoriesData =
            [
                {
                    name: (
                        <div>
                            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg' />
                            <p>Todas</p>
                        </div>
                    ),
                    id: 0
                }
            ]


        const mappedCategories = categoriesRawData.map(categorie => {
            if (categorie.tipoFinanca !== type) return;

            let line;
            line = (
                <div>
                    <div
                        style={{
                            ...categorieAndImageSelectedStyle,
                            backgroundColor: categorie.categoriaCor,
                        }}
                    >
                        {categorie.categoriaIcone &&
                            <img
                                src={categorie.categoriaIcone}
                                alt="Categorie icon"
                            />}
                    </div>
                    <p style={fontSizeMobileSelectedStyle}>{categorie.nomeCategoria}</p>
                </div>
            );

            return {
                name: line,
                id: categorie.id,
                hasImg: categorie?.categoriaIcone && true,
                color: categorie.categoriaCor
            };
        }).filter(cat => cat !== undefined);

        userCategoriesData = userCategoriesData.concat(mappedCategories);

        userCategoriesData.sort((a, b) => {
            if (a.id === 0) return -1;  // Mantém o sem categoria na posição 0 
            if (b.id === 0) return 1;
            if (a.hasImg === true && b.hasImg !== true) {
                return -1;
            }
            if (a.hasImg !== true && b.hasImg === true) {
                return 1;
            }
            return 0;
        });

        setCategoriesData(userCategoriesData);
    }, [categoriesRawData, isMobile]);
    const handleClickCategories = (item) => {
        setClickedFinance(null);
        if (item.id === 0) {
            setSelectedCategorie(notSelectedCategorie);
            applyFilter(selectedAccount, paidFilterName, null, orderFinances, selectedOrigin, selectedCreditCard);
            return
        }
        setSelectedCategorie(item);
        setFilterActive(true);
        applyFilter(selectedAccount, paidFilterName, item, orderFinances, selectedOrigin, selectedCreditCard);
    }










    //Paid received
    const handleClickPaidReceived = (item) => {
        setClickedFinance(null);
        if (item.id === 0) {
            setPaidFilterName(notSelectedPaid);
            applyFilter(selectedAccount, null, selectedCategorie, orderFinances, selectedOrigin, selectedCreditCard);
            return
        }
        setPaidFilterName(item);
        setFilterActive(true);
        applyFilter(selectedAccount, item, selectedCategorie, orderFinances, selectedOrigin, selectedCreditCard);
    }
    const paidReceived = type === 'income' ? 'recebidas' : 'pagas';
    const dropboxValuesPaidReceived =
        [
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllPaidFilterIcon.svg' alt="Categorie icon" />
                        <p >Todas</p>
                    </div>
                ),
                id: 0
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/PaidFilterIcon.svg' alt="Categorie icon" style={categorieAndImageSelectedStyle}/>
                        <p  style={fontSizeMobileSelectedStyle}>Apenas {paidReceived}</p>
                    </div>
                ),
                id: 1,
                color: '#48BD3E'
            },
            {
                name: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/NotPaidFilterIcon.svg' alt="Categorie icon" style={categorieAndImageSelectedStyle}/>
                        <p style={fontSizeMobileSelectedStyle}>Não {paidReceived}</p>
                    </div>
                ),
                id: 2,
                color: '#B13C3C'
            }
        ]





    /*order*/
    const handleClickOrderFinances = (item) => {
        setClickedFinance(null);
        if (item.id === 0) {
            setOrderFinances(notOrdered);
            applyFilter(selectedAccount, paidFilterName, selectedCategorie, null, selectedOrigin, selectedCreditCard)
            return
        }
        setOrderFinances(item);
        setFilterActive(true);
        applyFilter(selectedAccount, paidFilterName, selectedCategorie, item, selectedOrigin, selectedCreditCard)
    }
    const dropboxValuesOrderFinances = [
        {
            name: (
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllOrderIcon.svg' alt="Categorie icon" />
                    <p>Tirar ordenação</p>
                </div>
            ),
            id: 0
        },
        {
            name: (
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/DescOrderIcon.svg' alt="Categorie icon" style={categorieAndImageSelectedStyle}
                    />
                    <p style={fontSizeMobileSelectedStyle}>Maior para menor</p>
                </div>
            ),
            id: 1,
            color: '#5F6ACA'
        },
        {
            name: (
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AscDescOrderIcon.svg' alt="Categorie icon" style={categorieAndImageSelectedStyle}/>
                    <p style={fontSizeMobileSelectedStyle}>Menor para maior</p>
                </div>
            ),
            id: 2,
            color: '#414887'
        },
        {
            name: (
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/DayOrderIcon.svg' alt="Categorie icon"style={categorieAndImageSelectedStyle} />
                    <p style={fontSizeMobileSelectedStyle}>Por dia</p>
                </div>
            ),
            id: 3,
            color: '#5B4187'
        }
        /*,
            name: `Por ordem de criação` usar o ID,
            id: 3            
        */
    ]



    useEffect(() => {
        if (selectedAccount.id === 0 && paidFilterName.id === 0 && selectedCategorie.id === 0 && orderFinances.id === 0 && !selectedCreditCard && selectedOrigin.id === 0 && !directCreditCardFilterBySummary) {
            clearFilters();
        }
    }, [filterActive])
    useEffect(() => {
        applyFilter(selectedAccount, paidFilterName, selectedCategorie, orderFinances, selectedOrigin, selectedCreditCard);
    }, [financialRawData, financeTotal])


    const headerDesktop = (
        <header className='headerContainerOpenFinanceDesktop'>
            <div>
                <div>
                    <Dropbox
                        data={accountsData}
                        handleClick={handleClickAccount}
                        selectedValue={selectedAccount}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={originData}
                        handleClick={handleClickOrigin}
                        selectedValue={selectedOrigin}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={categoriesData}
                        handleClick={handleClickCategories}
                        selectedValue={selectedCategorie}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={dropboxValuesPaidReceived}
                        handleClick={handleClickPaidReceived}
                        selectedValue={paidFilterName}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={dropboxValuesOrderFinances}
                        handleClick={handleClickOrderFinances}
                        selectedValue={orderFinances}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                </div>

            </div>
            {filterActive && (
                <p onClick={clearFilters}>Limpar filtros</p>
            )}
        </header>
    )



    const [scrollFilterMobile, setScrollFilterMobile] = useState(3);
    const headerSmartphone = () => {
        const text1 = type === 'income' ? 'Minhas receitas' : 'Meus gastos';
        const text2 = type === 'income' ? 'das receitas' : 'dos gastos';

        const handleClickButtonScroll = (side) => {
            setScrollFilterMobile((prevValue) => {
                if (side === 'right') {
                    if (prevValue === 3) return -70;
                    if (prevValue === -70) return -97;
                }
                if (side === 'left') {
                    if (prevValue === -97) return -70;
                    if (prevValue === -70) return 3;
                }
                return prevValue;
            });
        };

        return (
            <header className='headerSmartphoneAllFinancesView'>
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/YellowArrow.svg' onClick={closeFullView} />
                    <p>{text1} de {monthName}</p>
                    <div />
                </div>
                <div>
                    <p>Total {text2}</p>
                    <p>R$ {financeTotal}</p>
                </div>
                <div style={{ marginLeft: `${scrollFilterMobile}vw` }}>
                    {scrollFilterMobile !== 3 && <button onClick={() => handleClickButtonScroll('left')} id='leftButtonScroll' />}
                    {[-70, 3].includes(scrollFilterMobile) && <button onClick={() => handleClickButtonScroll('right')} id='rightButtonScroll' />}

                    <Dropbox
                        data={accountsData}
                        handleClick={handleClickAccount}
                        selectedValue={selectedAccount}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={originData}
                        handleClick={handleClickOrigin}
                        selectedValue={selectedOrigin}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={categoriesData}
                        handleClick={handleClickCategories}
                        selectedValue={selectedCategorie}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={dropboxValuesPaidReceived}
                        handleClick={handleClickPaidReceived}
                        selectedValue={paidFilterName}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                    <Dropbox
                        data={dropboxValuesOrderFinances}
                        handleClick={handleClickOrderFinances}
                        selectedValue={orderFinances}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                </div>

                <div className='ClearFilterSmarthphone'>
                    {filterActive && (
                        <p onClick={clearFilters}>Limpar filtros</p>
                    )}
                </div>
            </header >
        )
    }
    return (
        <>
            {isMobile ? headerSmartphone() : headerDesktop}
        </>
    )
}

export default HeaderOpenCardView