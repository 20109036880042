import './UpdateWindow.css'
import React, { useEffect } from 'react';
import { useFinancialContext } from '../../../../FinancialContext';
import { useState } from 'react';
import axios from 'axios';
import useWindowWidth from '../../../../useWindowWidth';


const UpdateWindow = () => {
    const { messages, setMessages, axiosURL } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [messageOpen, setMessageOpen] = useState(null);

    const formatDateBrazzilianPattern = (date) => {
        return new Date(date).toLocaleDateString('pt-BR');
    }
    const handleClickMessage = (message) => {
        if (message.id != messageOpen?.id) {
            setMessageOpen(message);
            fetchSetReadMessage(message.id)
            return;
        }
        setMessageOpen(null)
    }
    const fetchSetReadMessage = async (messageId) => {
        try {
            const token = localStorage.getItem("SnnAcss");

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.post(`${axiosURL}/posts/${messageId}`, null, config);

            if (response.status === 200) {
                setMessages(prevData =>
                    prevData.map(message =>
                        message.id === messageId
                            ? { ...message, read: true }
                            : message
                    )
                );
            }

        } catch (error) {
            console.log(error)
        }
    };
    const renderCard = (message) => {

        const userProfile = message.user.userProfileImage ? `data:image/jpeg;base64,${message.user.userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg'
        let cardHeight;
        let readtext = message.read ? 'Lida' : 'Não lida';

        if (messageOpen?.id === message.id) {
            cardHeight = '20vw'
        } else {
            cardHeight = '5vw';
        }
        if (isMobile) {
            if (messageOpen?.id === message.id) {
                cardHeight = '80vw'
            } else {
                cardHeight = '15vw';
            }
        }

        return (
            <div style={{
                height: cardHeight
            }}
                onClick={() => handleClickMessage(message)}
                className='update-view-message-card'
            >
                <header>
                    <div>
                        <img src={userProfile} />
                        <div>
                            <h1>{message.titulo}</h1>
                            <h2>Mensagem do dia {formatDateBrazzilianPattern(message.dataPublicacao)}</h2>
                        </div>
                    </div>
                    <p>{!message.read && <div />}{readtext}</p>
                </header>
                {messageOpen?.id === message.id &&
                    <body>
                        <text>
                            {message.conteudo.split('\n').map((linha, index) => (
                                <p key={index}>{linha}</p>
                            ))}
                        </text>
                    </body>
                }
            </div>
        )
    }

    useEffect(() => {
        setMessages((prevValues) => prevValues)
    }, [isMobile])


    return (
        <div className='update-view-main-container'>
            {messages && messages.map(message => renderCard(message))}
        </div>
    );
};

export default UpdateWindow;