import React from 'react';
import Header from '../Page 1 - LandingPage/Header';
import './politicaPrivacidade.css'
import Footer from '../Page 1 - LandingPage/Footer';

const PoliticaDePrivacidade = () => {
    return (
        <div className='politica-de-privacidade-container'>
            <Header />
            <section className='container-info-privacy-policy'>
                <h1>Política de Privacidade</h1>

                <div>
                    <h2>1. Introdução</h2>
                    <p>Bem-vindo à Sunna! Esta Política de Privacidade explica como coletamos, usamos, armazenamos e protegemos suas informações ao utilizar nosso aplicativo. Ao acessar ou utilizar a Sunna, você concorda com as práticas descritas neste documento.</p>
                </div>

                <div>
                    <h2>2. Informações que Coletamos</h2>
                    <p>Podemos coletar os seguintes tipos de informações:</p>
                    <ul>
                        <li><strong>Informações fornecidas pelo usuário:</strong> Nome, e-mail, foto de perfil (se aplicável) e outras informações não obrigatórias que você pode nos oferecer após criar uma conta, como CPF, número de telefone e data de nasccimento.</li>
                        <li><strong>Dados financeiros:</strong> Registros de receitas e despesas cadastrados no aplicativo.</li>
                        <li><strong>Dados técnicos:</strong> Informações sobre o dispositivo utilizado, sistema operacional e logs de acesso.</li>
                        <li><strong>Dados de uso:</strong> Informações sobre como você interage com a Sunna, como frequência de uso e funcionalidades mais acessadas.</li>
                    </ul>
                </div>

                <div>
                    <h2>3. Como Utilizamos Seus Dados</h2>
                    <p>Usamos os dados coletados para:</p>
                    <ul>
                        <li>Fornecer e melhorar os serviços da Sunna;</li>
                        <li>Personalizar a experiência do usuário;</li>
                        <li>Analisar o uso do aplicativo para otimização e melhorias;</li>
                        <li>Cumprir obrigações legais e regulatórias.</li>
                    </ul>
                </div>

                <div>
                    <h2>4. Compartilhamento de Dados</h2>
                    <p>Não compartilhamos seus dados pessoais com terceiros, exceto quando necessário para:</p>
                    <ul>
                        <li>Cumprir obrigações legais;</li>
                        <li>Melhorar a experiência do usuário com integrações seguras;</li>
                        <li>Processos internos de análise de dados (sempre de forma anônima).</li>
                    </ul>
                </div>

                <div>
                    <h2>5. Segurança dos Dados</h2>
                    <p>Adotamos medidas de segurança para proteger suas informações contra acessos não autorizados, alterações ou vazamentos. No entanto, nenhum sistema é 100% seguro, e recomendamos que você proteja sua conta com uma senha forte.</p>
                </div>

                <div>
                    <h2>6. Seus Direitos</h2>
                    <p>Você tem o direito de:</p>
                    <ul>
                        <li>Acessar e corrigir suas informações pessoais;</li>
                        <li>Solicitar a exclusão dos seus dados;</li>
                        <li>Revogar consentimentos dados anteriormente.</li>
                    </ul>
                    <p>Para exercer seus direitos, entre em contato conosco pelo e-mail <a href="mailto:sunnaintern@gmail.com">sunnaintern@gmail.com</a>.</p>
                </div>


                <div>
                    <h2>7. Alterações na Política de Privacidade</h2>
                    <p>Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos os usuários sobre mudanças significativas.</p>
                </div>


                <div>
                    <h2>8. Contato</h2>
                    <p>Se tiver dúvidas sobre esta política, entre em contato pelo e-mail <a href="mailto:sunnaintern@gmail.com">sunnaintern@gmail.com</a>.</p>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default PoliticaDePrivacidade;