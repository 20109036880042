import './CategoriesGraph.css';
import Chart from 'chart.js/auto';
import React, { useRef, useEffect, useState } from 'react';
import { useFinancialContext } from '../../../../../../FinancialContext';
import EditCategoriesWindow from './EditCategoriesWindow';
import useWindowWidth from '../../../../../../useWindowWidth';
import EditButton from '../../../../../GenericComponents/EditButton';

function CategoriesGraph({ type }) {
    const isMobile = useWindowWidth();

    const { axiosURL, hideFinances, monthNumber, incomeData, spendData, handleOpenEditView,
        totalIncome, totalSpend, incomeCategoriesData, spendCategoriesData, updateDeleteCreateGroups, yearSelected, setCreditCardView
    } = useFinancialContext();

    const otherSpendsCategories = "Sem categoria";
    const otherIncomesCategories = "Sem categoria";

    const [incomeSpendData, setIncomeSpendData] = useState({ content: [] });

    const [categoriesData, setCategoriesData] = useState(type === 'income' ? incomeCategoriesData : spendCategoriesData);
    const [totalValue, setTotalValue] = useState(type === 'income' ? totalIncome : totalSpend)

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [categorieValuesData, setCategorieValuesData] = useState({});
    const [isDataReady, setIsDataReady] = useState(false);
    const [hasData, setHasData] = useState(false);



    const renderNoData = (alert) => (
        <div className='NoGroupInData'>{alert}</div>
    );
    const renderCategoriesChart = () => (
        <section className='bodyCategoriesGraph'>
            <div className='GraphCategories'>
                <canvas ref={chartRef} />
            </div>
            <div className='tableCategoriesContainer'>
                <table>
                    <thead>
                        <tr>
                            <th />
                            <th className='LeftTextTableCategories'>Categoria</th>
                            <th className='CenterTextTableCategories'>Valor</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='borderBottomTitleTableCategories' colSpan="2" />
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(categorieValuesData).map(categorie => {

                            return (
                                <tr key={categorie.id}>
                                    <td>
                                        <div className='colorgroup' style={{ backgroundColor: categorie.color }} />
                                    </td>
                                    <td className='fontWeight'>{categorie.name}</td>
                                    <td className='CenterTextTableCategories'>R$ {!hideFinances ? categorie.value.toFixed(2) : 0}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </section>
    );
    const renderCategoriesMobileView = () => (
        <table className='mobileTableCategoriesValues'>
            <thead>
                <tr>
                    <th />
                    <th className='textAlignLeft'>Nome</th>
                    <th>Valor</th>
                </tr>
            </thead>
            <tbody>
                {Object.values(categorieValuesData).map(categorie => {
                    return (
                        <tr key={categorie.id}>
                            <td><div className='colorgroup' style={{ backgroundColor: categorie.color }} /></td>
                            <td className='textAlignLeft' id='categorieNameTable'>{categorie.name}</td>
                            <td>
                                {hideFinances ? (
                                    <>●●</>
                                ) : (
                                    `R$ ${categorie.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

    const handleClickEditCategories = () => {
        handleOpenEditView(<EditCategoriesWindow type={type}/>, "Suas categorias já criadas");
    }


    const calculateChart = () => {
        if (incomeSpendData.content.length > 0) {
            setHasData(true);
            const groupsIdsIncomeSpend = incomeSpendData.content.map(element => element.categoria?.id || null);
            const groupsThatHaveIncomeSpend = categoriesData.filter(group => groupsIdsIncomeSpend.includes(group.id));

            let categoriesArray = groupsThatHaveIncomeSpend.map((group) => ({
                id: group.id,
                name: group.nomeCategoria,
                value: incomeSpendData.content
                    .filter(incomeSpend => incomeSpend.categoria?.id === group.id)
                    .reduce((acc, current) => acc + current.valor, 0),

                color: group.categoriaCor ? group.categoriaCor : "#FFFFFF",
                defaultCategorie: group.categoriaPadrao,
                categoriaIcone: group.categoriaIcone
            }));

            const totalValueFinancesWithoutCategories = incomeSpendData.content.reduce((acc, current) => acc + (!current || !current.categoria ? current.valor : 0), 0);

            const otherFinancesTotalValue = {
                id: null,
                name: type === "spend" ? otherSpendsCategories : otherIncomesCategories,
                value: totalValueFinancesWithoutCategories,
                color: type === "spend" ? "#DE4D47" : "#9FE0D7"
            };

            if (otherFinancesTotalValue.value > 0) {
                categoriesArray.push(otherFinancesTotalValue);
            }
            categoriesArray.sort((a, b) => b.value - a.value);

            setCategorieValuesData(categoriesArray);
            setIsDataReady(true);
            return;
        }
        setHasData(false);
    };
    useEffect(() => {
        if (isDataReady && chartRef.current) {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const myChartRef = chartRef.current.getContext("2d");

            chartInstance.current = new Chart(myChartRef, {
                type: 'doughnut',
                data: {
                    labels: categorieValuesData.map(item => item.name),
                    datasets: [
                        {
                            data: !hideFinances ? categorieValuesData.map(item => item.value) : 0,
                            backgroundColor: categorieValuesData.map(item => `${item.color}`),
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 2,
                    cutout: '40%',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: context => {
                                    return "R$" + context.raw.toLocaleString('pt-BR');
                                },
                            },
                        },
                    },
                },
            });
        }
    }, [isDataReady, categorieValuesData, totalValue, hideFinances, isMobile]);
    useEffect(() => {
        if (incomeData && spendData && spendCategoriesData && incomeCategoriesData) {
            const data = type === 'income' ? incomeData : spendData;
            setIncomeSpendData({
                content: data.content.map(finance => {
                    return finance ? finance : null;
                }).filter(item => item !== null)
            });
        }
    }, [incomeData, spendData, monthNumber, incomeCategoriesData, spendCategoriesData, yearSelected]);
    useEffect(() => {
        setCategoriesData(type === 'income' ? incomeCategoriesData : spendCategoriesData);
        setTotalValue(type === 'income' ? totalIncome : totalSpend);
    }, [incomeSpendData, monthNumber, incomeCategoriesData, spendCategoriesData, yearSelected]);
    useEffect(() => {
        calculateChart();
    }, [totalValue, incomeSpendData, monthNumber, categoriesData, yearSelected]);

    const header = () => {
        let title = `Categorias de ${type === 'income' ? 'receitas' : 'gastos'}`;

        return (
            <header className='chartCategoriesHeader'>
                <p>{title}</p>
                <div>
                    <EditButton handleClick={handleClickEditCategories} />
                </div>
            </header>
        )
    };

    const desktopTabletView = (
        <div className='desktopTabletCategoriesContainer'>
            {hasData && renderCategoriesChart()}
            {!hasData && renderNoData("Sem valores no período.")}
        </div>
    );

    const mobileView = (
        <div className='categoriesSmartPhoneContainer'>
            {hasData && renderCategoriesMobileView()}
            {!hasData && renderNoData("Sem valores no período.")}

        </div>
    );

    return (
        <div className='sizeCategoriesGraph'>
            <div className='FinancialCardSummaryContainer chartCategoriesContainer'>
                <>
                    {header()}
                    {isMobile ? mobileView : desktopTabletView}
                </>
            </div>
        </div>
    );
}

export default CategoriesGraph;
