import './MoreOptionsSelection.css';

const MoreOptionsSelection = ({values}) => {
    return (
        <div className='MoreOptionsSelectionContainer'>
            {values && values.map((value, index) => (
                <div
                    key={index}
                    onClick={value.onClick}
                >
                    <p>{value.title}</p>
                    <img src={value.img}/>
                </div>
            ))}
        </div>
    );
};


export default MoreOptionsSelection;