import './EditButton.css'

function EditButton({handleClick}){

    return(
        <button 
            onClick={handleClick}
            className='editGenericButtonComponent'    
        >
            <p>Editar</p>
            <img src='/Images_Dashboard_Summary/DefaultIcons/editGenericButton.svg'/>
        </button>
    )
}

export default EditButton;