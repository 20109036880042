import './CreditCardRendered.css'
import { useFinancialContext } from '../../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../../useWindowWidth';

function CreditCardRendered({ creditCard, openFullCard }) {
    const { hideFinances, setDirectCreditCardFilterBySummary } = useFinancialContext();
    const isMobile = useWindowWidth();

    const handleClickOpenFilter = () => {
        openFullCard('spend');
        setDirectCreditCardFilterBySummary(creditCard);
    }

    const desktopCard = () => {
        const text = creditCard?.conta.banks.type === 'BANK' ? 'Acessar fatura' : 'Ver gastos no mês';

        return (
            <div className='creditCardRenderedContainer'>
                <div>
                    <div>
                        <img src={creditCard.img} />
                        <div>
                            <p>{creditCard.nome_do_cartao}</p>
                            <p>{creditCard.dia_do_vencimento_da_fatura && `Vencimento dia ${creditCard.dia_do_vencimento_da_fatura.toString().padStart(2, '0')}`}</p>
                        </div>
                    </div>
                    <div onClick={handleClickOpenFilter}>
                        <p>{text}</p>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/greyArrow.svg' />
                    </div>
                </div>
                <div>
                    <div>
                        <p>Fatura atual: {hideFinances ? "●●" : <b>R$ {creditCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>}</p>
                    </div>
                </div>
            </div>
        )
    }

    const mobileCard = () => {
        const text = creditCard?.conta.banks.type === 'BANK' ? 'Acessar fatura' : 'Ver gastos no mês';

        return (
            <div className='creditCardRenderedContainer'>
                <div>
                    <div>
                        <img src={creditCard.img} />
                        <div>
                            <p>{creditCard.nome_do_cartao}</p>
                            <div onClick={handleClickOpenFilter}>
                                <p>{text}</p>
                                <img src='/Images_Dashboard_Summary/DefaultIcons/greyArrow.svg' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>
                            {hideFinances ? "●●" : <b>R$ {creditCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>}
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    return isMobile ? mobileCard() : desktopCard()

}

export default CreditCardRendered;