import './FinanceOpenCard.css';
import React, { useEffect, useState, useRef } from 'react';
import FinanceCardOpenView from './FinanceCardOpenView';
import axios from "axios";
import { format, addMonths, parseISO, differenceInMonths, subDays } from 'date-fns';
import ImportFinancesWindow from './ImportFinancesWindow';
import ToggleSliderSwitch from './ToggleSliderSwitch';
import { useFinancialContext } from '../../../../../../FinancialContext';
import HeaderOpenCardView from './HeaderOpenCardView';
import Dropbox from './Dropbox';
import useWindowWidth from '../../../../../../useWindowWidth';
import OverlaySelection from '../../../../DashboardSideBar/OverlaySelection';
import useViewportHeight from '../../../../../../useViewportHeight';
import { showAlertBox } from '../../../../../../staticData';
import AccountEdit from '../AccountsCard/AccountEdit';
import CreditCardInEdit from '../CreditCard/CreditCardInEdit';

function FinanceOpenCard({ type, financeData, createFinance, closeFullView, openFormsCreateNewFinance, setOpenFormsCreateNewFinance }) {
    useViewportHeight();
    const formsRef = useRef(null);
    const requestOngoingRef = useRef(false);

    const { yearSelected, monthNumber, monthName, categoriesData, askForFeedback, handleOpenEditView,
        updateDeleteCreateFinance, axiosURL, setCreateNewGroup, setIncomeData, setSpendData, creditCardData, accountsData, fetchAccountBalanceData
    } = useFinancialContext();
    const isMobile = useWindowWidth();


    //solução para mobile visto que o css não está funcionando
    const categorieAndImageSelectedStyle = isMobile ? {
        minWidth: '4.5vw',
        minHeight: '4.5vw',
        maxWidth: '4.5vw',
        maxHeight: '4.5vw'
    } : {}

    const fontSizeMobileSelectedStyle = isMobile ? {
        fontSize: '3.5vw'
    } : {}

    const [financialData, setFinancialData] = useState(financeData ? financeData : null);

    const [currentDate, setCurrentDate] = useState(format(new Date(), `yyyy-MM-dd`));
    const [daysOfMonth, setDaysOfMonth] = useState(null);
    const [financeTotal, setFinanceTotal] = useState(null);

    const [clickedFinance, setClickedFinance] = useState(null);

    const [detailFinances, setDetailFinances] = useState(true); //Detalhar o formulário
    const [settingSpecificDay, setSettingSpecificDay] = useState('today');
    const [calendarEndRecurrence, setCalendarEndRecurrence] = useState(false);

    const [alertUpdateFinance, setAlertUpdateFinance] = useState(false)
    const [overlayComponentEditDelete, setOverlayComponentEditDelete] = useState(null);

    const [numbersToEndRecurrence, setNumbersToEndRecurrence] = useState(0);
    const [activeRecurrenceSelectedFinance, setActiveRecurrenceSelectedFinance] = useState(false);
    const [deleteFinanceWindow, setDeleteFinanceWindow] = useState(false);

    const [closeFilterWindows, setCloseFilterWindows] = useState(false);
    const [activateImportFinances, setActivateImportFinances] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState();
    const [selectedCategorie, setSelectedCategorie] = useState();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    const [selectedCreditCard, setSelectedCreditCard] = useState();

    const [inputs, setInputs] = useState({
        data: null,
        nome: '',
        valor: 0,
        categoria: null,
        recorrente: false,
        fimRecorrencia: null,
        observacoes: null,
        dataFim: null,
        pagaOuRecebida: false,
        specific: false
    });

    const notSelectedAccount = {
        id: 0,
        name: 'Selecionar conta',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AccountFilterIcon.svg',
        color: '#3E6BBD'
    }
    const notSelectedPaymentMethod = {
        id: 0,
        name: 'Selecionar meio de pagamento',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg',
        color: '#3EBD80'
    }
    const notSelectedCategorie = {
        id: 0,
        name: 'Selecionar categoria',
        img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg',
        color: '#3EBDB6'
    }
    const handleInputName = (name) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            nome: name.target.value
        }));
    }
    const handleInputValue = (value) => {
        let rawValue = value.replace(/\D/g, '');

        const valueAsNumber = Number(rawValue) / 100;

        const valueFormatted = valueAsNumber.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        setInputs((prevInputs) => ({
            ...prevInputs,
            valor: valueFormatted
        }));

    }
    const handleInputDate = (e) => {
        const day = e.target.value.replace(/\D/g, '');
        const lastDayInMonth = getDaysOfMonth().slice(-1)[0];
        if (day <= lastDayInMonth && day !== 0) {
            const formattedYear = String(yearSelected);
            const formattedMonth = adicionarZeroEsquerdaMes(monthNumber);
            const formattedDay = adicionarZeroEsquerdaMes(day).toString().slice(-2);

            const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

            setInputs((prevInputs) => ({
                ...prevInputs,
                data: formattedDate
            }));
        } else {
            showAlertBox('error', "A data informada é inválida.");
        }
    }
    const handleSwitchRecurrence = (recurrence) => {
        if (!recurrence && !clickedFinance) {
            setNumbersToEndRecurrence(0);
            setInputs((prevInputs) => ({
                ...prevInputs,
                fimRecorrencia: null
            }));
            setActiveRecurrenceSelectedFinance(false);
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            recorrente: recurrence
        }));
        setActiveRecurrenceSelectedFinance(true);
    };
    const handleInputEndRecurrence = (numbersOfMonths) => {
        const numberOfMonthsFormatted = numbersOfMonths.replace(/\D/g, '');

        //Colocar como zero o número, limpar o final da recorrência e tirar do input caso o usuário apague o que inseriu
        if (numberOfMonthsFormatted === null || numberOfMonthsFormatted === '' || numberOfMonthsFormatted < 1) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                fimRecorrencia: null
            }));
            setNumbersToEndRecurrence(0);
            return;
        }

        setNumbersToEndRecurrence(parseInt(numberOfMonthsFormatted));
        const inicialDate = parseISO(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-01`)
        const finalRecurrence = format(addMonths(inicialDate, numberOfMonthsFormatted - 1), 'yyyy-MM-dd');

        setInputs((prevInputs) => ({
            ...prevInputs,
            fimRecorrencia: finalRecurrence
        }));
    }
    const handleInputObservations = (observation) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            observacoes: observation.target.value
        }));
    }
    const handleClickSetDate = (date) => {
        setSettingSpecificDay(date);

        let dateSelected;
        if (date === 'today') {
            dateSelected = `${yearSelected}-${monthNumber}-${currentDate.toString().slice(-2)}`;
        } else {
            dateSelected = format(subDays(parseISO(`${yearSelected}-${monthNumber.padStart(2, '0')}-${currentDate.toString().slice(-2)}`), 1), 'yyyy-MM-dd');
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            data: dateSelected
        }));
    }
    const getFinanceOriginImage = (origin) => {
        switch (origin) {
            case 'PIX':
                return {
                    img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/pixIcon.svg',
                    id: -1,
                    color: '#08BFB1',
                    name: 'Pix'
                };
            case 'TRANSFERENCIA':
                return {
                    img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/transferIcon.svg',
                    id: -2,
                    color: '#0848BF',
                    name: 'Transferência'
                };
            case 'BOLETO':
                return {
                    img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/boletoIcon.svg',
                    id: -3,
                    color: '#242424',
                    name: 'Boleto'
                };
            case 'CARTAO_DE_DEBITO':
                return {
                    img: '/Images_Dashboard_Summary/DefaultIcons/FilterIcons/debitCardIcon.svg',
                    id: -4,
                    color: '#843031',
                    name: 'Cartão de débito'
                };
        }
    };
    const handleClickScrollFormsSmartphone = () => {
        if (formsRef.current) {
            setTimeout(() => {
                formsRef.current.scrollTo({
                    top: 1000,
                    behavior: "smooth",
                });
            }, 100)
        }
    };





    const getDateSelected = () => {
        return new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-${inputs.data.toString().slice(-2)}`);
    }
    const adicionarZeroEsquerdaMes = (mes) => {
        return mes.padStart(2, '0');
    }

    //Função de requisição para o backend de deletar finanças
    const fetchDeleteFinance = async () => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }
        const token = localStorage.getItem("SnnAcss");

        try {
            requestOngoingRef.current = true;
            const response = await axios.delete(
                `${axiosURL}/${type}/${parseInt(clickedFinance.id)}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 204) {
                updateDeleteCreateFinance("delete", clickedFinance.id, type);
                fetchAccountBalanceData();
                clearForms();
            } else {
                throw new Error("Erro inesperado ao excluir a finança.");
            }
        } catch (error) {
            showAlertBox("error", `Ocorreu um erro ao excluir a finança. Código 001.${clickedFinance.id}.0.${monthNumber}`);
            console.error("Erro 001 ao deletar finança:", error);
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função de requisição para o backend de alteração de finanças
    const fetchUpdateFinance = async (FinalDateAux, finance) => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        try {
            requestOngoingRef.current = true;
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            let updatedFinance;
            if (!finance) {
                const account = selectedAccount?.id;

                updatedFinance = finance ? finance : {
                    id: clickedFinance.id,
                    data: inputs.data,
                    nome: inputs.nome,
                    valor: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                    categoria: inputs.categoria?.id,
                    recorrente: inputs.recorrente,
                    fimRecorrencia: inputs.fimRecorrencia ? new Date(inputs.fimRecorrencia) : null,
                    observacoes: inputs.observacoes ? inputs.observacoes : '',
                    dataFimFinanca: FinalDateAux ? FinalDateAux : clickedFinance.dataFim,
                    specificFinances: clickedFinance.specificFinances,
                    pagaOuRecebida: clickedFinance.pagaOuRecebida,
                    specificId: clickedFinance.specific ? clickedFinance.specificId : null,
                    conta: account,
                    cartaoCredito: inputs.cartaoCredito?.id,
                    origemFinanca: inputs.origemFinanca
                }
            } else {//Alteração apenas de finança paga
                updatedFinance = {
                    id: finance.id,
                    data: finance.data,
                    nome: finance.nome,
                    valor: finance.valor,
                    categoria: finance.categoria?.id,
                    recorrente: finance.recorrente,
                    fimRecorrencia: finance.fimRecorrencia,
                    observacoes: finance.observacoes,
                    dataFimFinanca: finance.dataFim,
                    specificFinances: finance.specificFinances,
                    pagaOuRecebida: finance.pagaOuRecebida,
                    conta: finance.account ? finance.account?.id : null,
                    cartaoCredito: finance.creditCard?.id,
                    origemFinanca: finance.origemFinanca
                }
            }

            const response = await axios.put(`${axiosURL}/${type}`, updatedFinance, config);

            if (response.status === 200) {
                if (!finance) clearForms(); //Se for só alteraão de pago, ele não limpa o formulário, mas se for alteração de finança completa, ele limpa
                if (FinalDateAux) { //FinalDateAux é um parâmetro que só aparece quando deleta de agora em diante
                    updateDeleteCreateFinance("delete", response.data.id, type);
                    fetchAccountBalanceData();
                    return;
                }

                if ((clickedFinance?.recorrente && !inputs.recorrente)) {//Usuário desativando a recorrência enquanto está em em um mês adiante
                    updateDeleteCreateFinance("delete", response.data.id, type);
                }

                else updateDeleteCreateFinance("update", response.data, type);
                fetchAccountBalanceData();
                if (clickedFinance && finance) setClickedFinance(response.data);
            } else {
                throw new Error("Erro inesperado ao alterar a finança.");
            }
        } catch (error) {
            const financeId = finance ? finance.id : clickedFinance.id;
            const specificId = (clickedFinance && clickedFinance.specific) ? clickedFinance.specificId : 0;
            showAlertBox("error", `Ocorreu um erro ao alterar a finança. Código 002.${financeId}.${specificId}.${monthNumber}`);
            console.log(error)
            if (finance) throw new Error(); //dar rollback no pago/recebido
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função para dar um fetch e criar uma nova finança, seja receita ou gasto
    const fetchNewFinance = async () => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        if (!inputs.nome || inputs.valor <= 0 || !inputs.data) {
            showAlertBox("error", "O nome e o valor são obrigatórios!");
            return;
        }

        if (inputs.data.toString().slice(-2) == '00') {
            showAlertBox("error", "A data informada é inválida.");
            return;
        }

        try {
            requestOngoingRef.current = true;
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const financeDate = getDateSelected();
            const newFinance = {
                data: financeDate,
                nome: inputs.nome,
                valor: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoria: inputs.categoria?.id,
                recorrente: inputs.recorrente,
                fimRecorrencia: inputs.fimRecorrencia ? new Date(inputs.fimRecorrencia) : null,
                observacoes: inputs.observacoes,
                conta: inputs.conta,
                cartaoCredito: inputs.cartaoCredito?.id,
                criadoPorMobile: isMobile ? true : false,
                origemFinanca: inputs.origemFinanca
            }

            const response = await axios.post(`${axiosURL}/${type}`, newFinance, config);

            if (response.status === 201) {
                clearForms();
                updateDeleteCreateFinance("create", response.data, type);
                askForFeedback();
            } else {
                throw new Error("Erro inesperado ao criar a finança.");
            }
        } catch (error) {
            showAlertBox("error", "Ocorreu um erro ao criar a finança. Código 003");
            console.log(error)
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função para criar uma finança - "ativa" é a variável que define se é para desativar ou não a finança específica
    const fetchCreateSpecificFinance = async (ativa, finance) => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        //Verificando se a finança para ser atualizada é específica, está se repetindo, e é a finança responsável pela repetição - 
        //É necessário passar a repetição para a do mês seguinte e criar uma nova específica neste mês
        if (clickedFinance && clickedFinance.specific && (
            clickedFinance.repetirFinancaEspecifica &&
            format(new Date(yearSelected, parseInt(monthNumber) - 1, '01'), 'MM/yyyy') === format(parseISO(clickedFinance.data), `MM/yyyy`)
        )) {
            fetchUpdateRepetedSpecificFinance(ativa);
            return;
        }

        //Verficando se já há uma finança específica neste mês
        if (clickedFinance
            && clickedFinance.specific
            && !clickedFinance.repetirFinancaEspecifica) {
            fetchUpdateSpecificFinance(ativa);
            return;
        }

        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const financeDate = getDateSelected();
        let newSpecificFinance;

        if (!finance) {//alteração de apenas check de finança
            const account = selectedAccount?.id;

            newSpecificFinance = {
                idFinancaReferenciada: clickedFinance.id,
                mesReferencia: financeDate,
                nomeEspecifico: inputs.nome,
                valorEspecifico: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoriaEspecificaId: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: ativa,
                pagaOuRecebida: false,
                conta: account,
                criadoPorMobile: isMobile ? true : false,
                cartaoCredito: inputs.cartaoCredito?.id,
                origemFinanca: inputs.origemFinanca
            }
        } else {
            const date = (finance.data instanceof Date) ? finance.data : parseISO(finance.data);

            let day = format(date, 'dd');
            const lastDayInMonth = getDaysOfMonth().slice(-1)[0];

            if (day > lastDayInMonth || day === 0) {
                day = lastDayInMonth;
            }

            const financeDate = new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-${day}`)

            newSpecificFinance = {
                idFinancaReferenciada: finance.id,
                pagaOuRecebida: finance.pagaOuRecebida,
                mesReferencia: financeDate,
                nomeEspecifico: finance.nome,
                valorEspecifico: finance.valor,
                categoriaEspecificaId: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMes: true,
                conta: finance.account ? finance.account?.id : null,
                criadoPorMobile: isMobile ? true : false,
                cartaoCredito: finance.creditCard?.id,
                origemFinanca: finance.origemFinanca
            }
        }

        try {
            requestOngoingRef.current = true;
            const response = await axios.post(`${axiosURL}/${type}/specificFinance`, newSpecificFinance, config);
            if (response.status === 201) {
                if (!finance) clearForms();
                if (!ativa) updateDeleteCreateFinance("delete", clickedFinance.id, type); //Deletando apenas neste mês
                else updateDeleteCreateFinance("update", response.data, type); //Atualizando apenas neste mês
                fetchAccountBalanceData();
                if (clickedFinance && finance) setClickedFinance(response.data);
            } else {
                throw new Error("Erro inesperado ao criar a finança específica.");
            }
        } catch (error) {
            const financeId = finance ? finance.id : clickedFinance.id;
            showAlertBox("error", `Ocorreu um erro ao alterar a finança. Código 004.${financeId}.0.${monthNumber}`);
            console.log(error)
            if (finance) throw new Error(); //dar rollback no pago/recebido
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função para atualizar uma finança específica
    const fetchUpdateSpecificFinance = async (ativa, finance) => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        let updatedSpecificFinance;

        if (!finance) {//alteração de apenas check de finança
            const account = selectedAccount?.id;
            const financeDate = getDateSelected();

            updatedSpecificFinance = {
                id: clickedFinance.specificId,
                idFinancaReferenciada: clickedFinance.id,
                dataEspecifica: financeDate,
                nomeFinancaEspecifica: inputs.nome,
                valorFinancaEspecifica: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                idCategoriaFinancaEspecifica: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: ativa,
                pagoOuRecebidaEspecifica: clickedFinance.pagaOuRecebida,
                conta: account,
                cartaoCredito: inputs.cartaoCredito?.id,
                origemFinanca: inputs.origemFinanca
            }
        } else {
            updatedSpecificFinance = {
                id: finance.specificId,
                idFinancaReferenciada: finance.id,
                dataEspecifica: finance.data,
                nomeFinancaEspecifica: finance.nome,
                valorFinancaEspecifica: finance.valor,
                idCategoriaFinancaEspecifica: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMes: true,
                pagoOuRecebidaEspecifica: finance.pagaOuRecebida,
                conta: finance.account ? finance.account.id : null,
                cartaoCredito: finance.creditCard?.id,
                origemFinanca: finance.origemFinanca
            }
        }

        try {
            requestOngoingRef.current = true;
            const response = await axios.put(`${axiosURL}/${type}/specificFinance`, updatedSpecificFinance, config);
            if (response.status === 200) {

                if (!finance) clearForms();
                if (!ativa) updateDeleteCreateFinance("delete", clickedFinance.id, type); //Deletando apenas neste mês
                else updateDeleteCreateFinance("update", response.data, type); //Atualizando apenas neste mês
                if (clickedFinance && finance) setClickedFinance(response.data);
                fetchAccountBalanceData();
            }
        } catch (error) {

            const specificId = finance ? finance.specificId : clickedFinance.specificId;
            const financeId = finance ? finance.id : clickedFinance.id;
            showAlertBox("error", `Ocorreu um erro ao alterar a finança. Código 005.${financeId}.${specificId}.${monthNumber}`);
            console.log(error)
            if (finance) throw new Error(); //dar rollback no pago/recebido
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função para atualizar a finança a partir do mês atual - Atualiza o mês atual e os futuros, os anteriores permanecem o mesmo
    const fetchUpdateNowOnFinance = async () => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        const financeDate = getDateSelected();
        const account = selectedAccount?.id;

        try {
            requestOngoingRef.current = true;
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const newSpecificFinance = {
                idFinancaReferenciada: clickedFinance.id,
                mesReferencia: financeDate,
                nomeEspecifico: inputs.nome,
                valorEspecifico: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoriaEspecificaId: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: true,
                pagaOuRecebida: clickedFinance.pagaOuRecebida,
                dataFinalEspecifica: "",
                repetirFinanca: true,
                specific: clickedFinance.specific,
                specificId: clickedFinance.specific ? clickedFinance.specificId : null,
                conta: account,
                criadoPorMobile: isMobile ? true : false,
                cartaoCredito: inputs.cartaoCredito?.id,
                origemFinanca: inputs.origemFinanca
            }

            const response = await axios.post(`${axiosURL}/${type}/updateNowOn`, newSpecificFinance, config);
            if (response.status === 201) {
                clearForms();
                updateDeleteCreateFinance("update", response.data, type);
                fetchAccountBalanceData();
            }

        } catch (error) {
            const specificId = (clickedFinance && clickedFinance.specific) ? clickedFinance.specificId : 0;
            showAlertBox("error", `Ocorreu um erro ao alterar a finança. Código 006.${clickedFinance.id}.${specificId}.${monthNumber}`);
            console.log(error)
        } finally {
            requestOngoingRef.current = false;
        }
    };
    //Função para editar a finança específica em "apenas este mês" que se repete - No caso ela cria uma nova específica para o mês que o usuário está vendo com as informações atuais, 
    //e cria uma nova específica para o mês seguinte, com as informações da finança
    const fetchUpdateRepetedSpecificFinance = async (ativa, finance) => {
        if (requestOngoingRef.current) {
            showAlertBox("warning", "Há uma transação em andamento, aguarde.");
            return;
        }

        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        let updatedSpecificFinance;
        if (!finance) {
            const account = selectedAccount?.id;
            const financeDate = getDateSelected();

            updatedSpecificFinance = {
                id: clickedFinance.specificId,
                idFinancaReferenciada: clickedFinance.id,
                dataEspecifica: financeDate,
                nomeFinancaEspecifica: inputs.nome,
                valorFinancaEspecifica: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                idCategoriaFinancaEspecifica: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: ativa,
                pagoOuRecebidaEspecifica: clickedFinance.pagaOuRecebida,
                conta: account,
                criadoPorMobile: isMobile ? true : false,
                cartaoCredito: inputs.cartaoCredito?.id,
                origemFinanca: inputs.origemFinanca
            }
        } else {
            updatedSpecificFinance = {
                id: finance.specificId,
                idFinancaReferenciada: finance.id,
                dataEspecifica: finance.data,
                nomeFinancaEspecifica: finance.nome,
                valorFinancaEspecifica: finance.valor,
                idCategoriaFinancaEspecifica: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMes: true,
                pagoOuRecebidaEspecifica: finance.pagaOuRecebida,
                conta: finance.account ? finance.account.id : null,
                criadoPorMobile: isMobile ? true : false,
                cartaoCredito: finance.creditCard?.id,
                origemFinanca: inputs.origemFinanca
            }
        }

        try {
            requestOngoingRef.current = true;
            const response = await axios.put(`${axiosURL}/${type}/specificFinance/updateRepetedSpecific`, updatedSpecificFinance, config);
            if (response.status === 200) {
                if (!finance) clearForms();
                if (!ativa) updateDeleteCreateFinance("delete", clickedFinance.id, type); //Deletando apenas neste mês
                else updateDeleteCreateFinance("update", response.data, type);
                if (clickedFinance && finance) setClickedFinance(response.data);
                fetchAccountBalanceData();
            }
        } catch (error) {
            const specificId = finance ? finance.specificId : clickedFinance.specificId;
            const financeId = finance ? finance.id : clickedFinance.id;
            showAlertBox("error", `Ocorreu um erro ao alterar a finança. Código 007.${financeId}.${specificId}.${monthNumber}`);
            console.log(error)
            if (finance) throw new Error(); //dar rollback no pago/recebido
        } finally {
            requestOngoingRef.current = false;
        }
    };



    const handleClickPaidReceived = async (finance, checked) => {
        try {
            if (clickedFinance && finance.id === clickedFinance.id) {
                setClickedFinance({
                    ...clickedFinance,
                    pagaOuRecebida: checked
                })
            }

            const { specific, recorrente, repetirFinancaEspecifica } = finance;
            if (!specific && !recorrente) {
                await fetchUpdateFinance(null, finance);

            } else if (!specific && recorrente) {
                await fetchCreateSpecificFinance(true, finance);
            } else if (specific && !repetirFinancaEspecifica) {
                await fetchUpdateSpecificFinance(true, finance);
            } else if (specific && repetirFinancaEspecifica) {
                const dateSelected = new Date(yearSelected, monthNumber - 1, finance.data.toString().slice(-2))
                const dateSelectedFormatted = format(dateSelected, `yyyy/MM`);
                const dataFin = format(parseISO(finance.data), `yyyy/MM`)

                if (dateSelectedFormatted === dataFin) {
                    await fetchUpdateRepetedSpecificFinance(true, finance);
                } else {
                    const newFinance = {
                        ...finance,
                        data: dateSelected
                    }

                    await fetchCreateSpecificFinance(true, newFinance);
                }
            }
        } catch (error) {
            return true;
        }
    };

    //Função para parar a propaganação. Usada para fechar janelas
    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }
    //Função para fechar a janela do calendário do formulário
    const handleCloseAllWindows = (event) => {
        event.stopPropagation();
        setCalendarEndRecurrence(false)
        setDeleteFinanceWindow(false)
        setAlertUpdateFinance(false)
        setCloseFilterWindows(true);
        setActivateImportFinances(false);
        setCloseFilterWindows(true);
        setClickedFinance(null)
    };

    //Função para criar uma array com todos os números que sejam equivalentes aos dias do mês que o usuário está vendo
    const getDaysOfMonth = () => {
        const MonthsWithThirtyOneDays = ["01", "03", "05", "07", "08", "10", "12"];
        const MonthsWithThirtyDays = ["04", "06", "09", "11"];
        const monthSelectedByUser = adicionarZeroEsquerdaMes(monthNumber);
        let days = [];

        if (MonthsWithThirtyOneDays.includes(monthSelectedByUser)) {
            days = Array.from({ length: 31 }, (_, index) => index + 1);
        } else if (MonthsWithThirtyDays.includes(monthSelectedByUser)) {
            days = Array.from({ length: 30 }, (_, index) => index + 1);
        } else if (monthSelectedByUser === "02") {
            const year = yearSelected;
            days = (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0)) // Verificação de bissexto
                ? Array.from({ length: 29 }, (_, index) => index + 1)
                : Array.from({ length: 28 }, (_, index) => index + 1);
        }

        return days;
    };
    //Função para limpar todos os inputs do formulário
    const clearForms = () => {
        setInputs({
            data: currentDate,
            nome: '',
            valor: 0,
            categoria: null,
            recorrente: false,
            fimRecorrencia: null,
            observacoes: '',
            dataFim: null,
            pagaOuRecebida: false,
            specific: false
        })
        setSettingSpecificDay('today')
        setAlertUpdateFinance(false);
        setActiveRecurrenceSelectedFinance(false);
        setNumbersToEndRecurrence(0);
        setCreateNewGroup('');
        setOpenFormsCreateNewFinance(false);
        setClickedFinance(null)
        setSelectedAccount(null)
        setSelectedCategorie(null)
        setOverlayComponentEditDelete(false)
        setDeleteFinanceWindow(false);
    }

    //Função para selecionar qual finança foi clicada, o card se expandir e ele se tornar o primeiro da posição
    const handleClickedFinance = (finance) => {
        const financeToMove = financeData.content.find((fin) => fin.id === finance.id);
        let updatedContent = [
            financeToMove,
            ...financialData.content.filter((fin) => fin.id !== finance.id)
        ];

        updatedContent = {
            ...financialData,
            content: updatedContent
        };

        setFinancialData(updatedContent);
        setClickedFinance(finance);

        updatedContent = [
            financeToMove,
            ...financeData.content.filter((fin) => fin.id !== finance.id)
        ];

        updatedContent = {
            ...financeData,
            content: updatedContent
        };

        if (type === 'income') {
            setIncomeData(updatedContent)
        } else {
            setSpendData(updatedContent)
        }

    }
    const calculateNumberOfFinances = (data) => {
        let totalValue = 0

        data.content.forEach((finance) => {
            if (finance) {
                totalValue += finance.valor;
            }
        })
        totalValue = totalValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        setFinanceTotal(totalValue);
    }


    const headerView = () => {
        const categoriesSelect = categoriesData.filter(categorie => categorie.tipoFinanca === type);
        return (
            <HeaderOpenCardView
                financeTotal={financeTotal}
                type={type}
                setCloseFilterWindows={setCloseFilterWindows}
                closeFilterWindows={closeFilterWindows}
                categoriesRawData={categoriesSelect}
                setFinancialData={setFinancialData}
                financialRawData={financeData}
                setClickedFinance={setClickedFinance}
                clickedFinance={clickedFinance}
                creditCardRawData={creditCardData}
                accountRawData={accountsData}
                monthName={monthName}
                closeFullView={closeFullView}
            />
        )
    }
    const renderRightForms = () => {
        /*Lógica e handlers*/
        let buttonText;
        let title;
        if (type === 'income' && clickedFinance) {
            buttonText = "Alterar receita";
            title = 'Editar receita';
        } else if (type === 'spend' && clickedFinance) {
            buttonText = "Alterar gasto";
            title = 'Editar gasto';
        } else if (type === 'income' && !clickedFinance) {
            buttonText = "Criar receita";
            title = 'Nova receita';
        } else if (type === 'spend' && !clickedFinance) {
            buttonText = "Criar gasto";
            title = 'Novo gasto';
        }
        const updateValuePortion = () => {
            if (!numbersToEndRecurrence) return;
            const valorFloat = (parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')) / numbersToEndRecurrence).toFixed(2);
            handleInputValue(valorFloat.toString());
        }
        const dateEndRecurrence = clickedFinance && (inputs.fimRecorrencia || clickedFinance.fimRecorrencia);




        /*Header*/
        const handleActiveImportFinances = (e) => {
            handleCloseAllWindows(e);
            setDetailFinances(false);
            setClickedFinance(null);
            e.stopPropagation();
            setActivateImportFinances(true)
        }
        const renderHeaderForms = (
            <header>
                <div>
                    {openFormsCreateNewFinance && <img onClick={clearForms} src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/YellowArrow.svg' />}
                    <p>{title}</p>
                    <div />
                </div>
                <div onClick={handleActiveImportFinances} >
                    <img src='Images_Dashboard_Summary/DefaultIcons/importFinancesIcon.svg'
                        alt='Importar finanças' />
                    <p>Importar</p>
                </div>
            </header>
        )




        /*Body*/
        const inputNameField = (
            <div className='marginTop5'>
                <label>Nome</label>
                <input type='text' onChange={handleInputName} value={inputs.nome} autoFocus maxLength={95} />
            </div>
        )
        const inputValueField = (
            <div id='InputValueFinance'>
                <label>Valor</label>
                <input type='text' onChange={(e) => handleInputValue(e.target.value)} value={`R$ ${inputs.valor}`} maxLength={14} inputMode="numeric" />
            </div>
        )
        const inputDateField = (
            <>
                <div onClick={() => setSettingSpecificDay(null)} style={{ width: !settingSpecificDay ? '20%' : '35%' }}>
                    {settingSpecificDay ?
                        <>
                            Selecionar dia
                            <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/yellowCalendar.svg' />
                        </>
                        :
                        <>
                            <input autoFocus type='text' onChange={handleInputDate} value={"Dia " + inputs.data.toString().slice(-2)} inputMode="numeric" />
                        </>

                    }

                </div>
                <>
                    <div onClick={() => handleClickSetDate('yesterday')} style={{
                        backgroundColor: settingSpecificDay === 'yesterday' && '#EEC842',
                        color: settingSpecificDay === 'yesterday' && '#FAFAFA'
                    }}>Ontem</div>
                    <div onClick={() => handleClickSetDate('today')} style={{
                        backgroundColor: settingSpecificDay === 'today' && '#EEC842',
                        color: settingSpecificDay === 'today' && '#FAFAFA'
                    }}>Hoje</div>
                </>
            </>
        )
        const detailFinancesButton = (
            <div className='detailFinancesContainer'>
                <p p onClick={() => setDetailFinances(!detailFinances)}
                    className='buttonDetailFinance' >
                    {detailFinances ? 'Ocultar' : 'Detalhar informações'}
                    < img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/grayArrow.svg'
                        style={{
                            transform: detailFinances ? 'rotate(180deg)' : null,
                            transition: 'transform 0.3s ease'
                        }}
                    />
                </p >
            </div>
        )



        const switchRecurrence = (
            <section>
                <span>Repetir nos próximos meses</span>


                {isMobile ?
                    <ToggleSliderSwitch
                        onChange={handleSwitchRecurrence}
                        active={activeRecurrenceSelectedFinance}
                        width={"12vw"}
                        height={"6vw"}
                        circle={"4.5vw"}
                        bottom={"0.75vw"}
                        leftOff={"0.4vw"}
                        leftOn={"1.0vw"}
                        cursor={"pointer"}
                        borderRadius={'3vw'}
                        translate={'6vw'}
                    />
                    :
                    <ToggleSliderSwitch
                        onChange={handleSwitchRecurrence}
                        active={activeRecurrenceSelectedFinance}
                        width={"2.7vw"}
                        height={"1.25vw"}
                        circle={"1vw"}
                        bottom={"0.16vw"}
                        leftOff={"0.13vw"}
                        leftOn={"0.14vw"}
                        cursor={"pointer"}
                        borderRadius={'1vw'}
                        translate={'1.3vw'}
                    />
                }
            </section>
        )
        const inputObservationField = (
            <div id='InputObservationFinance' >
                <label>Observações</label>
                <textarea
                    type='text'
                    rows="2"
                    cols="50"
                    style={{
                        resize: 'none',
                        width: '98%',
                        height: '3vw'
                    }}
                    maxLength={250}
                    onChange={handleInputObservations}
                    value={inputs.observacoes}
                />
            </div>
        )
        const renderCategorieSelectForms = () => {
            let userCategoriesData =
                [
                    {
                        name: (
                            <div
                                style={{
                                    backgroundColor: "#ec1c2414",
                                    width: '100%',
                                    marginLeft: '0%',
                                    height: '100%',
                                }}
                            >
                                <p>Sem categoria</p>
                            </div>
                        ),
                        id: 0
                    }
                ]

            const mappedCategories = categoriesData.map(categorie => {
                if (categorie.tipoFinanca !== type) return;

                const icon = categorie.categoriaIcone;

                let line;
                if (icon) {

                    line = (
                        <div>
                            <div
                                style={{
                                    ...categorieAndImageSelectedStyle,
                                    backgroundColor: categorie.categoriaCor,
                                }}
                            >
                                <img src={icon} alt="Categorie icon" />
                            </div>
                            <p style={fontSizeMobileSelectedStyle}>{categorie.nomeCategoria}</p>
                        </div>
                    );
                } else {
                    line = (
                        <div>
                            <div
                                style={{
                                    ...categorieAndImageSelectedStyle,
                                    backgroundColor: categorie.categoriaCor,
                                }}
                            />
                            <p style={fontSizeMobileSelectedStyle}>{categorie.nomeCategoria}</p>
                        </div>
                    )
                }

                return {
                    name: line,
                    id: categorie.id,
                    color: categorie.categoriaCor
                };
            }).filter(cat => cat !== undefined);

            userCategoriesData = userCategoriesData.concat(mappedCategories);

            userCategoriesData.sort((a, b) => {
                if (a.id === 0) return -1;  // Mantém o sem categoria na posição 0 
                if (b.id === 0) return 1;
                if (a.hasImg === true && b.hasImg !== true) {
                    return -1;
                }
                if (a.hasImg !== true && b.hasImg === true) {
                    return 1;
                }
                return 0;
            });

            const handleClickSelectCategorie = (categoria) => {
                if (categoria.id === 0) {
                    setSelectedCategorie(null);
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        categoria: null
                    }));
                    return;
                }
                setSelectedCategorie(categoria);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    categoria: categoria
                }));
            }

            const categorieSelected = selectedCategorie ? selectedCategorie : notSelectedCategorie;

            return (
                <div>
                    <label>Vincular à uma categoria</label>
                    <Dropbox
                        data={userCategoriesData}
                        handleClick={handleClickSelectCategorie}
                        selectedValue={categorieSelected}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                    />
                </div>
            )
        }
        const renderSelectAccount = () => {

            let userAccountData =
                [
                    {
                        name: (
                            <div
                                style={{
                                    backgroundColor: "#ec1c2414",
                                    width: '100%',
                                    marginLeft: '0%',
                                    height: '100%',
                                }}
                            >
                                <p>Sem conta associada</p>
                            </div>
                        ),
                        id: 0
                    }
                ]

            const mappedAccounts = accountsData.map(account => {
                const logo = account.banks.urlImagem;

                let line;
                if (logo) {
                    line = (
                        <div>
                            <img src={logo} alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                            <p style={fontSizeMobileSelectedStyle}>{account.accountName}</p>
                        </div>
                    );
                }
                return {
                    name: line,
                    id: account.id,
                    color: account.banks.corBanco
                };
            });

            if (mappedAccounts.length === 0) {
                const newAccount = {
                    name: (
                        <div style={{ backgroundColor: "#65C37A26" }} >
                            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AccountFilterIcon.svg' />
                            <p>Criar nova conta</p>
                        </div>
                    ),
                    id: -1
                }

                userAccountData = userAccountData.concat(newAccount);
            }

            userAccountData = userAccountData.concat(mappedAccounts);

            const handleClickSelectAccount = (account) => {
                if (account.id === 0) {
                    setSelectedCreditCard(null);
                    setSelectedAccount(null);
                    setSelectedPaymentMethod(null)
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        conta: null,
                        origemFinanca: null,
                        cartaoCredito: null
                    }));
                    return;
                } else if (account.id === -1) {
                    handleOpenEditView(<AccountEdit />, "Suas contas já cadastradas")
                    return;
                }
                setSelectedAccount(account);
                setSelectedCreditCard(null);
                setSelectedPaymentMethod(null);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    conta: account.id
                }));
            }

            const accountSelected = selectedAccount ? selectedAccount : notSelectedAccount;

            return (
                <div>
                    <label>Vincular à uma conta</label>
                    <Dropbox
                        data={userAccountData}
                        handleClick={handleClickSelectAccount}
                        selectedValue={accountSelected}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        maxHeight={'13vw'}
                    />
                </div>
            )
        }
        const renderPaymentMethod = () => {
            if (!selectedAccount) return;

            let userCreditCardData =
                [
                    {
                        name: (
                            <div
                                style={{
                                    backgroundColor: "#ec1c2414",
                                    width: '100%',
                                    marginLeft: '0%',
                                    height: '100%',
                                }}
                            >
                                <p>Nenhum</p>
                            </div>
                        ),
                        id: 0
                    },
                    {
                        name: (
                            <div>
                                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/pixIcon.svg' alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                                <p style={fontSizeMobileSelectedStyle}>Pix</p>
                            </div>
                        ),
                        id: -1,
                        color: '#08BFB1'
                    },
                    {
                        name: (
                            <div>
                                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/transferIcon.svg' alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                                <p style={fontSizeMobileSelectedStyle}>Transferência</p>
                            </div>
                        ),
                        id: -2,
                        color: '#0848BF'
                    },
                    {
                        name: (
                            <div>
                                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/boletoIcon.svg' alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                                <p style={fontSizeMobileSelectedStyle}>Boleto</p>
                            </div>
                        ),
                        id: -3,
                        color: '#242424'
                    }
                ]




            if (type === 'spend') {
                const debitCard = {
                    name: (
                        <div>
                            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/debitCardIcon.svg' alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                            <p style={fontSizeMobileSelectedStyle}>Cartão de débito</p>
                        </div>
                    ),
                    id: -4,
                    color: '#843031'
                }
                userCreditCardData = userCreditCardData.concat(debitCard);

                const mappedCreditCards = creditCardData.content.map(credit => {
                    if (selectedAccount.id !== credit.account.id) return null;

                    const logo = credit.account.banks.urlImagem;

                    let line;
                    if (logo) {
                        const name = credit.account.banks.type === 'BANK' ? 'Cartão de crédito' : 'Cartão'
                        line = (
                            <div>
                                <img src={logo} alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                                <p style={fontSizeMobileSelectedStyle}>{name} {credit.nome_cartao.replace('Cartão', '')}</p>
                            </div>
                        );
                    }
                    return {
                        name: line,
                        id: credit.id,
                        color: credit.account.banks.corBanco
                    };
                }).filter(credit => credit !== null);

                if (mappedCreditCards.length < 1) { //se a conta que o corno selecionou não tem cartão
                    const oCreditcard = {
                        name: (
                            <div style={{ backgroundColor: "#65C37A26" }} >
                                <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' alt="Bank Logo" />
                                <p>Criar cartão de crédito</p>
                            </div>
                        ),
                        id: -5,
                        color: '#3EBD80'
                    }
                    userCreditCardData = userCreditCardData.concat(oCreditcard)
                } else {
                    userCreditCardData = userCreditCardData.concat(mappedCreditCards);
                }

            }



            const handleUpdateSelectedPayment = (object, value) => {
                setSelectedPaymentMethod(object);
                setSelectedCreditCard(null);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    origemFinanca: value,
                    cartaoCredito: null
                }));
            }

            const handleClickSelectPaymentMethod = (origemFinanca) => {
                switch (origemFinanca.id) {
                    case 0:
                        handleUpdateSelectedPayment(null, null);
                        return;
                    case -1:
                        handleUpdateSelectedPayment(origemFinanca, "PIX");
                        return;
                    case -2:
                        handleUpdateSelectedPayment(origemFinanca, "TRANSFERENCIA");
                        return;
                    case -3:
                        handleUpdateSelectedPayment(origemFinanca, "BOLETO");
                        return;
                    case -4:
                        handleUpdateSelectedPayment(origemFinanca, "CARTAO_DE_DEBITO");
                        return;
                    case -5:
                        handleOpenEditView(<CreditCardInEdit />, "Seus cartões já criados")
                        return;
                }
                setSelectedCreditCard(origemFinanca);
                setSelectedPaymentMethod(origemFinanca);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    cartaoCredito: origemFinanca,
                    origemFinanca: "CARTAO_DE_CREDITO"
                }));
            }


            const origemFinanca = selectedPaymentMethod ? selectedPaymentMethod : notSelectedPaymentMethod;
            const title = type === 'income' ? "Selecionar origem da receita" : "Selecionar meio de pagamento";

            return (
                <div>
                    <label>{title}</label>
                    <Dropbox
                        data={userCreditCardData}
                        handleClick={handleClickSelectPaymentMethod}
                        selectedValue={origemFinanca}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        maxHeight={'10vw'}
                        onClickOption={handleClickScrollFormsSmartphone}
                        onCickAtive={true}
                    />
                </div>
            )
        }
        const formsDetailed = (
            <>
                <div id='InputRecurrenceFinance' className='marginTop5'>
                    {switchRecurrence}

                    {inputs.recorrente && (
                        <div className='setLimitDeactivateRecurrenceContainer'>
                            <div className='InputEndRecurrenceFinance'>
                                <button className='buttonRecurrenceDetails' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCalendarEndRecurrence(!calendarEndRecurrence);
                                    setDeleteFinanceWindow(false);
                                }}>
                                    <p>{
                                        inputs.fimRecorrencia
                                            ? ` Parcelas até ${format(parseISO(inputs.fimRecorrencia), 'yyyy/MM')}`
                                            : 'Número de parcelas'}
                                    </p>
                                    <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/blackCalendar.svg' alt='Calendário' />
                                </button>

                                {calendarEndRecurrence && (
                                    <div className='windowSetEndRecurrence' onClick={stopPropagation}>
                                        <input
                                            type='text'
                                            id='inputEndRecurrenceNumberOfMonths'
                                            maxLength={2}
                                            onChange={(e) => handleInputEndRecurrence(e.target.value)}
                                            value={numbersToEndRecurrence}
                                            inputMode="numeric"
                                        />
                                        <p>O valor de {`R$ ${inputs.valor}`} é:</p>
                                        <div>
                                            <button onClick={(e) => {
                                                stopPropagation(e);
                                                setCalendarEndRecurrence(false);
                                            }}>Da parcela</button>
                                            <button onClick={(e) => {
                                                stopPropagation(e);
                                                setCalendarEndRecurrence(false);
                                                updateValuePortion();
                                            }}>Do total</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {(clickedFinance?.recorrente && !inputs.recorrente) && ( //caso a finança seja recorrente e o usuário esteja desativando
                        <p>Desativando a recorrência, a finança existirá apenas no mês de criação.</p>
                    )}


                </div>
                {inputObservationField}
                <div id='InputDropBoxFinance' className='creditCardPaddingSmartphone'>
                    {renderCategorieSelectForms()}
                </div>
                <div id='InputDropBoxFinance' className='creditCardPaddingSmartphone'>
                    {renderSelectAccount()}
                </div>
                {selectedAccount &&
                    <div id='InputDropBoxFinance' className='creditCardPaddingSmartphone'>
                        {renderPaymentMethod()}
                    </div>
                }
            </>
        )


        const bodyForms = (
            <div
                className='formsCreateEditFinance'
                ref={formsRef}
            >
                {inputNameField}
                <div>
                    <div>
                        {inputValueField}
                    </div>
                    <div>
                        {inputDateField}
                    </div>
                </div>

                {detailFinancesButton}
                {detailFinances && formsDetailed}
            </div >
        )







        /*Footer*/
        const colorOnlyThisMonth = alertUpdateFinance ? "#4CAA45" : "#CB2636"
        const colorNowOn = alertUpdateFinance ? "#2A6D24" : "#9B232F"
        const colorAll = alertUpdateFinance ? "#34CF27" : "#F14B5C"

        const valuesUpdate = [
            // Alteração de todos os meses
            <div style={{ borderColor: colorAll }} onClick={() => fetchUpdateFinance()}>
                <p style={{ color: colorAll }}>Alterar todos os meses</p>
                <div style={{ backgroundColor: colorAll }}>
                    <img src='Images_Dashboard_Summary/DefaultIcons/EditAllMonths.svg' />
                </div>
            </div>,

            // Alteração deste mês para frente
            !dateEndRecurrence && (
                <div style={{ borderColor: colorNowOn }} onClick={fetchUpdateNowOnFinance}>
                    <p style={{ color: colorNowOn }}>Alterar deste mês para frente</p>
                    <div style={{ backgroundColor: colorNowOn }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditNowOn.svg' />
                    </div>
                </div>
            ),

            // Alteração apenas deste mês
            ((
                (clickedFinance && inputs.fimRecorrencia === clickedFinance.fimRecorrencia) ||
                (clickedFinance && !inputs.fimRecorrencia && !clickedFinance.fimRecorrencia)
            ) && (
                    <div style={{ borderColor: colorOnlyThisMonth }} onClick={() => fetchCreateSpecificFinance(true)}>
                        <p style={{ color: colorOnlyThisMonth }}>Alterar apenas este mês</p>
                        <div style={{ backgroundColor: colorOnlyThisMonth }}>
                            <img src='Images_Dashboard_Summary/DefaultIcons/EditSpecific.svg' />
                        </div>
                    </div>
                ))
        ];
        const handleClickCreateFinance = (e) => {
            if (clickedFinance === null) {
                fetchNewFinance();
            } else if (!clickedFinance.recorrente || !inputs.recorrente) {
                fetchUpdateFinance(null);
            } else {
                e.stopPropagation();
                setAlertUpdateFinance(true);
                setDeleteFinanceWindow(false);
                setOverlayComponentEditDelete(true)
            }
        }
        const updateCreateButton = (
            <div className='buttonUpdateFinanceContainer'>
                <button id='CreateFinanceButton' className='FormsFinanceViewButton' onClick={handleClickCreateFinance}>
                    {buttonText}
                </button>
            </div>
        )




        const valuesDelete = [
            (
                <div
                    key="delete-specific"
                    style={{ borderColor: '#CA2C2C' }}
                    onClick={() => fetchCreateSpecificFinance(false)}
                >
                    <p style={{ color: '#CA2C2C' }}>Excluir apenas este mês</p>
                    <div style={{ backgroundColor: '#CA2C2C' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditSpecific.svg' alt="Excluir apenas este mês" />
                    </div>
                </div>
            ),
            (!dateEndRecurrence && (
                <div
                    key="delete-forward"
                    style={{ borderColor: '#932626' }}
                    onClick={() => {
                        const financeDate = new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-01`);
                        fetchUpdateFinance(financeDate);
                    }}
                >
                    <p style={{ color: '#932626' }}>Excluir deste mês para frente</p>
                    <div style={{ backgroundColor: '#932626' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditNowOn.svg' alt="Excluir deste mês para frente" />
                    </div>
                </div>
            )),
            (
                <div
                    key="delete-all"
                    style={{ borderColor: '#B13C3C' }}
                    onClick={fetchDeleteFinance}
                >
                    <p style={{ color: '#B13C3C' }}>Excluir todos os meses</p>
                    <div style={{ backgroundColor: '#B13C3C' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditAllMonths.svg' alt="Excluir todos os meses" />
                    </div>
                </div>
            )
        ];
        const handleClickDeleteFinance = (e) => {
            if (!clickedFinance?.recorrente) {
                fetchDeleteFinance();
            } else {
                e.stopPropagation();
                setDeleteFinanceWindow(true);
                setAlertUpdateFinance(false);
                setOverlayComponentEditDelete(true);
            }
        };
        const deleteFinanceButton = (
            <div className='buttonUpdateFinanceContainer'>
                <button
                    id='DeleteFinanceButton'
                    className='FormsFinanceViewButton'
                    onClick={handleClickDeleteFinance}>
                    Excluir
                </button>
            </div>
        )





        const renderFooterForms = (
            <footer>
                <div
                    style={{
                        justifyContent: clickedFinance !== null ? "space-between" : "flex-end",
                        height: 'fit-content'
                    }}>
                    {clickedFinance !== null && deleteFinanceButton}
                    {updateCreateButton}
                </div>
            </footer>
        )
        return (
            <>
                {isMobile ? (
                    <section
                        className={`overflowFormsCreateUpdateFinanceSmartphone ${openFormsCreateNewFinance ? "active" : ""}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div>
                            {renderHeaderForms}
                            <form>{bodyForms}</form>
                        </div>
                        {renderFooterForms}
                        {(overlayComponentEditDelete && clickedFinance && (deleteFinanceWindow || alertUpdateFinance)) && (
                            <OverlaySelection
                                setOverlayOn={deleteFinanceWindow ? setDeleteFinanceWindow : setAlertUpdateFinance}
                                values={deleteFinanceWindow ? valuesDelete : valuesUpdate}
                                color={deleteFinanceWindow ? '#CB2636' : '#4BB763'}
                                setOverLayComponent={setOverlayComponentEditDelete}
                                maxHeight={'30%'}
                            />
                        )}
                    </section>
                ) : (
                    <aside className='RightSideFilterAndForms' onClick={(e) => e.stopPropagation()}>
                        {renderHeaderForms}
                        <form>{bodyForms}</form>
                        {renderFooterForms}
                        {(overlayComponentEditDelete && clickedFinance && (deleteFinanceWindow || alertUpdateFinance)) && (
                            <OverlaySelection
                                setOverlayOn={deleteFinanceWindow ? setDeleteFinanceWindow : setAlertUpdateFinance}
                                values={deleteFinanceWindow ? valuesDelete : valuesUpdate}
                                color={deleteFinanceWindow ? '#CB2636' : '#4BB763'}
                                setOverLayComponent={setOverlayComponentEditDelete}
                                maxHeight={'30%'}
                            />
                        )}
                    </aside>
                )}
            </>
        );
    }




    const footerSmartphone = () => {
        let buttonText = null;

        const handleClickButtonFooter = (e) => {
            setOpenFormsCreateNewFinance(true);
            e.stopPropagation();
        }

        const bgColorButtonFooter = type === 'income' ? '#489946' : '#ED4C5C';

        if (!openFormsCreateNewFinance) {
            if (type === 'income') {
                buttonText = clickedFinance ? "Alterar receita" : "Nova receita";
            } else if (type === 'spend') {
                buttonText = clickedFinance ? "Alterar gasto" : "Novo gasto";
            }
        }

        return (
            <footer className='FooterSmartPhoneOpenFinances'>
                {buttonText && (
                    <button
                        className='FormsFinanceViewButton'
                        onClick={handleClickButtonFooter}
                        style={{ backgroundColor: bgColorButtonFooter }}
                    >
                        {buttonText}
                    </button>
                )}
            </footer>
        );
    };

    const footerDestop = () => (
        <footer className='FooterDesktopOpenFinances'
            style={{
                backgroundColor: type === 'income' ? '#489946B2' : '#ED4C5CB2'
            }}
        >
            <div
                style={{
                    backgroundColor: type === 'income' ? '#489946' : '#ED4C5C'
                }}
            >
                <img src='/Images_Dashboard_Summary/DefaultIcons/SifraoBrancoIcon.svg' />
            </div>
            <p>R$ {financeTotal}</p>
        </footer>
    )


    //Hook para alterar o formulário para receber as informações da finança clicada e colocar elas em todos os inputs do formulário.
    useEffect(() => {
        setOverlayComponentEditDelete(false)
        if (clickedFinance !== null) {
            setSettingSpecificDay(null);
            setDetailFinances(true);
            setActiveRecurrenceSelectedFinance(clickedFinance.recorrente);


            const financeDay = clickedFinance.data.toString().slice(-2);
            const lastDayInMonth = getDaysOfMonth().slice(-1)[0];
            let financeDateFixed;

            if (financeDay <= lastDayInMonth && financeDay !== 0) {
                financeDateFixed = clickedFinance.data;
            } else {
                financeDateFixed = `${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-${lastDayInMonth}`;
            }

            setInputs({
                data: financeDateFixed,
                nome: clickedFinance.nome,
                valor: clickedFinance.valor.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                categoria: clickedFinance.categoria ? clickedFinance.categoria : null,
                recorrente: clickedFinance.recorrente ? clickedFinance.recorrente : false,
                fimRecorrencia: clickedFinance.fimRecorrencia ? clickedFinance.fimRecorrencia : null,
                observacoes: clickedFinance.observacoes ? clickedFinance.observacoes : '',
                dataFim: clickedFinance.dataFim,
                pagaOuRecebida: clickedFinance.pagaOuRecebida,
                specificFinances: clickedFinance.specificFinances,
                specific: clickedFinance.specific,
                cartaoCredito: clickedFinance.creditCard ? clickedFinance.creditCard : null,
                conta: clickedFinance.account,
                origemFinanca: clickedFinance.origemFinanca
            })


            if (clickedFinance.fimRecorrencia !== null) {
                const financeDate = parseISO(clickedFinance.data);
                const endRecurrence = parseISO(clickedFinance.fimRecorrencia)

                const monthsDifferenceTotal =
                    endRecurrence.getFullYear() === financeDate.getFullYear()
                        && endRecurrence.getMonth() === financeDate.getMonth() ? 1
                        : differenceInMonths(endRecurrence, financeDate) + 1;

                setNumbersToEndRecurrence(monthsDifferenceTotal);
            } else {
                setNumbersToEndRecurrence(0);
            }



            if (clickedFinance.categoria) {
                const logo = clickedFinance.categoria.categoriaIcone;
                const line = (
                    <div>
                        <div
                            style={{
                                ...categorieAndImageSelectedStyle,
                                backgroundColor: clickedFinance.categoria.categoriaCor
                            }}
                        >
                            {logo &&

                                <img src={logo} alt="Categorie icon" style={{ display: 'block' }} />
                            }
                        </div>
                        <p style={fontSizeMobileSelectedStyle}>{clickedFinance.categoria.nomeCategoria}</p>
                    </div>
                );

                const categorie = {
                    name: line,
                    id: clickedFinance.categoria.id,
                    color: clickedFinance.categoria.categoriaCor
                }
                setSelectedCategorie(categorie)
            } else {
                setSelectedCategorie(null)
            }

            if (clickedFinance.account) {

                const logo = clickedFinance.account.banks.urlImagem;

                const line = (
                    <div>
                        <img src={logo} alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                        <p style={fontSizeMobileSelectedStyle}>{clickedFinance.account.accountName}</p>
                    </div>
                );

                const account = {
                    name: line,
                    id: clickedFinance.account.id,
                    color: clickedFinance.account.banks.corBanco
                }

                setSelectedAccount(account);

                if (clickedFinance.creditCard) {
                    const logo = clickedFinance.creditCard.account.banks.urlImagem;
                    const name = clickedFinance.credit?.account.banks.type === 'BANK' ? 'Cartão de crédito' : 'Cartão'

                    const line = (
                        <div>
                            <img src={logo} alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                            <p style={fontSizeMobileSelectedStyle}>{name} {clickedFinance.creditCard.nome_cartao.replace('Cartão', '')}</p>
                        </div>
                    );

                    const creditCard = {
                        name: line,
                        id: clickedFinance.creditCard.id,
                        color: clickedFinance.creditCard.account.banks.corBanco
                    }
                    setSelectedPaymentMethod(creditCard)
                } else {
                    setSelectedPaymentMethod(null);
                }

            } else {
                setSelectedAccount(null);
            }


            if (clickedFinance.origemFinanca && !clickedFinance.creditCard) {
                const origin = getFinanceOriginImage(clickedFinance.origemFinanca);

                const line = (
                    <div>
                        <img src={origin.img} alt="Bank Logo" style={categorieAndImageSelectedStyle} />
                        <p style={fontSizeMobileSelectedStyle}>{origin.name}</p>
                    </div>
                );

                const payment = {
                    name: line,
                    id: origin.id,
                    color: origin.color
                }
                setSelectedPaymentMethod(payment)
            } else if (!clickedFinance.creditCard) {
                setSelectedPaymentMethod(null);
            }

        }
        else {
            clearForms();
        }
    }, [clickedFinance]);

    useEffect(() => {
        const lastDayInMonth = getDaysOfMonth().slice(-1)[0];
        let day = currentDate.toString().slice(-2);

        if (day > lastDayInMonth || day === "00") {
            day = lastDayInMonth;
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            data: `${yearSelected}-${monthNumber}-${day}`,
        }))
    }, [])

    useEffect(() => {
        calculateNumberOfFinances(financialData);
    }, [financialData])

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        }
    }, [clickedFinance]);

    useEffect(() => {
        if (createFinance) {
            setOpenFormsCreateNewFinance(true)
        }
    }, [createFinance])

    return (
        <div
            className='WindowFullSpendIncomeCardOverlay'
            onClick={handleCloseAllWindows}
        >
            <div className='MainContainerOpenFinanceCard'>
                {headerView()}

                <section
                    className='OpenFinancialCardsContainer'
                    ref={containerRef}
                >
                    {financialData &&
                        financialData.content.map((finance) => {
                            return finance && (
                                <FinanceCardOpenView
                                    key={finance.id}
                                    financeCard={finance}
                                    type={type}
                                    categoriesData={categoriesData}
                                    daysOfMonth={daysOfMonth}
                                    monthNumber={adicionarZeroEsquerdaMes(monthNumber)}
                                    yearSelected={yearSelected}
                                    handleClickedFinance={handleClickedFinance}
                                    clickedFinance={clickedFinance}
                                    setClickedFinance={setClickedFinance}
                                    handleCheckFinance={handleClickPaidReceived}
                                    getDaysOfMonth={getDaysOfMonth}
                                    adicionarZeroEsquerdaMes={adicionarZeroEsquerdaMes}
                                />
                            );
                        })
                    }
                </section>

                {isMobile ? footerSmartphone(financeTotal) : footerDestop(financeTotal)}
            </div>
            {renderRightForms()}

            {activateImportFinances && (
                <>
                    <ImportFinancesWindow
                        type={type}
                        setActivateImportFinances={setActivateImportFinances}
                    />
                    <div className='importFinancesWindowOpacityOverlay' />
                </>
            )}
        </div>
    );
}

export default FinanceOpenCard;

