import './ResumoView.css'
import ReducedFinancialCard from './ResumoViewCards/ReducedFinancialCard';
import FinanceOpenCard from './ResumoViewCards/FinanceOpenCard';
import TotalBalanceCard from './ResumoViewCards/TotalBalanceCard';
import MainCategories from './ResumoViewCards/MainCategories';
import { useFinancialContext } from '../../../../FinancialContext';
import CreditCardCard from './ResumoViewCards/CreditCard';
import IncomeSpendSumarryCard from './ResumoViewCards/IncomeSpendSumarryCard';
import AccountsCard from './ResumoViewCards/AccountsCard';
import useWindowWidth from '../../../../useWindowWidth';

function ResumoView({ viewClicked, openFormsCreateNewFinance,
    setOpenFormsCreateNewFinance, openFullCard, setIncomeCardSelected, setSpendCardSelected, spendCardSelected, incomeCardSelected }) {

    const { incomeData, spendData, incomeCategoriesData, spendCategoriesData, setDirectCreditCardFilterBySummary } = useFinancialContext();
    const isMobile = useWindowWidth();

    const width100FixForMobile = isMobile ? {
        width: '100%'
    } : {}

    const closeFullView = async () => {
        setIncomeCardSelected(false);
        setSpendCardSelected(false);
        setDirectCreditCardFilterBySummary(null);
    }

    const renderShortFinancialCard = (type) => {
        return (
            <div onClick={() => openFullCard(type)} className='ShortFinancialCard'>
                <ReducedFinancialCard
                    title={type === "income" ? "Minhas receitas" : "Meus gastos"}
                    type={type}
                />
            </div>
        );
    }

    const renderFullCard = (selected, financeData, categoriesData, type) => {
        return selected && financeData && (
            <div className="MainIncomeSpendFullCardClick MainIncomeSpendFullCardOverlay" onClick={() => closeFullView(false)}>
                <FinanceOpenCard
                    type={type}
                    financeData={financeData}
                    categoriesDataProps={categoriesData}
                    viewClicked={viewClicked}
                    closeFullView={closeFullView}
                    openFormsCreateNewFinance={openFormsCreateNewFinance}
                    setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                />
            </div>
        );
    }



    return (
        <div className='MainIncomeAndSpendPart'>
            <section className='BalanceIncomeAndSpend'>
                <div className='BalanceTopVision'
                    style={width100FixForMobile}
                >
                    <div style={width100FixForMobile}><TotalBalanceCard/></div>
                    <div className='grey-line-total-income-total-spend' />
                    <div><IncomeSpendSumarryCard type={"income"} /></div>
                    <div><IncomeSpendSumarryCard type={"spend"} /></div>
                </div>
                <div className='IncomeAndSpendView'>
                    {renderShortFinancialCard("income")}
                    {renderShortFinancialCard("spend")}
                    <section className='AccountsRightSide'>
                        <AccountsCard />
                    </section>
                </div>
            </section>

            <section className='CategoriesCreditCardEstatisticsSection'>
                <div>
                    <CreditCardCard
                        hasData={true}
                        openEditView={false}
                        renderNoData={false}
                        setError_message={null}
                        setOpenEditView={false}
                        creditCardView={true}
                        viewClicked={false}
                        openFullCard={openFullCard}
                    />
                </div>
                <div>
                    <MainCategories />
                </div>
            </section>

            {renderFullCard(incomeCardSelected, incomeData, incomeCategoriesData, "income")}
            {renderFullCard(spendCardSelected, spendData, spendCategoriesData, "spend")}
            <div className='spaceSmartPhoneBottomBarSummary' />
        </div>

    )

}

export default ResumoView;