import './TotalBalanceCard.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import { useEffect, useState } from 'react';
import useWindowWidth from '../../../../../../useWindowWidth';
import { showAlertBox } from '../../../../../../staticData';
import axios from 'axios';

function TotalBalanceCard() {
    const { totalValue, hideFinances, incomeData,
        spendData,  setHideFinances, totalBalance, setTotalBalance, axiosURL, fetchAccountBalanceData, totalIncomeReceivedLeft,totalSpendPaidLeft, } = useFinancialContext();

    const isMobile = useWindowWidth();

    const [detailFinances, setDetailFinances] = useState(false);
    const [changingBalance, setChangingBalance] = useState(false);

    const [acumuladoSelected, setAcumuladoSelected] = useState(totalBalance.preferenciaSaldo)

    const buttonSelectedStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }

    const disabledButtonBalancePreference = {
        backgroundColor: '#585858',
        color: '#fafafa',
        cursor: 'default'
    }

    const titleOptionAcumulado = "Formato de saldo acumulado"
    const subtitleOptionAcumulado = (<p>Neste formato apenas <b>gastos pagos</b>, <b>receitas recebidas</b> e o <b>saldo das contas</b> impactarão no seu saldo, e ele se mantém e acumula ao passar dos meses, refletindo o saldo das suas contas bancárias</p>)

    const titleOptionMensal = "Orçamento mensal separado"
    const subtitleOptionMensal = (<p>Neste formato todas as finanças impactam seu saldo, e <b>o saldo de cada mês é totalmente independente</b>. <span>Alterar para orçamento mensal irá zerar o saldo acumulado e o das contas</span>.</p>)


    const handleActivateBalanceSelection = () => {
        setChangingBalance(!changingBalance);
    }
    const stopPropagation = (e) => {
        e.stopPropagation()
    }
    const handleSelectButton = (option) => {
        setAcumuladoSelected(option);
    }
    const fetchChangeBalancePreference = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.put(`${axiosURL}/account/changeBalancePreference/${acumuladoSelected}`, {}, config);

            if (response.status === 200) {
                showAlertBox('success', "Preferência de saldo alterado com sucesso!");
                setAcumuladoSelected(response.data.preferenciaSaldo)
                setTotalBalance(response.data)
                setChangingBalance(false);
                fetchAccountBalanceData();
            }

        } catch (error) {
            console.log(error)
        }


    }
    const totalValueExpectedTotal = () => {
        if (totalBalance.preferenciaSaldo == "Acumulado") {
            return (
                <>
                    <p>Projetado fim do mês</p>
                    <p>R$ {((totalIncomeReceivedLeft- totalSpendPaidLeft) + totalBalance.balance).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </>
            )
        }
        return (
            <p>Orçamento do mês</p>
        )
    }
    const totalInMonth = () => {
        if (hideFinances) {
            return "●●";
        }
        return totalBalance.preferenciaSaldo === "Acumulado"
            ? totalBalance.balance.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : totalValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };



    const desktopTotal = (
        <section className='containerUserBalance'>
            <div>
                <p>Seu saldo atual</p>
                <img src='Images_Dashboard_Summary/SummaryView/Header/changeBalanceOption.svg' onClick={handleActivateBalanceSelection} />
            </div>

            <div className='circleIconTotalCard'>
                <p>R$ <b>{totalInMonth()}</b></p>
                {hideFinances ? (
                    <img src='Images_Dashboard_Summary/SummaryView/Header/closedEye.svg' onClick={() => setHideFinances(false)} />
                ) :
                    <img src='Images_Dashboard_Summary/SummaryView/Header/openEye.svg' onClick={() => setHideFinances(true)} />
                }
            </div>

            <div /> {/*grey line*/}

            <div>
                {totalValueExpectedTotal()}
            </div>
        </section>
    )
    const smartphoneTotal = (
        <>
            <section className='smartphoneTotalCard'>
                {(incomeData && spendData) && (
                    <>
                        <div>
                            <div>
                                <p>Seu saldo atual</p>
                                <img src='Images_Dashboard_Summary/SummaryView/Header/changeBalanceOption.svg' onClick={handleActivateBalanceSelection} />
                            </div>
                            <div>
                                <p>R$ {totalInMonth()}</p>
                            </div>
                        </div>
                        <div>
                            {totalValueExpectedTotal()}
                        </div>
                    </>
                )}
            </section>
        </>
    )


    const changeBalanceWindow = (

        <div className='changingBalanceModelOverlay' onClick={handleActivateBalanceSelection}>
            <div onClick={stopPropagation}>
                <header>
                    <h1>Alterar formato de saldo</h1>
                    <h2>Altere o formato do cálculo do saldo de acordo com a sua preferência</h2>
                </header>

                <body>
                    <div>
                        <button style={acumuladoSelected === "Acumulado" ? buttonSelectedStyle : {}} onClick={() => handleSelectButton("Acumulado")}>
                            <img src='Images_Dashboard_Summary/SummaryView/Header/SelecaoAcumulado.svg' />
                            <p>Formato de saldo acumulado</p>
                        </button>
                        <button style={acumuladoSelected === "Mensal" ? buttonSelectedStyle : {}} onClick={() => handleSelectButton("Mensal")}>
                            <img src='Images_Dashboard_Summary/SummaryView/Header/OrcamentoMensal.svg' />
                            <p>Orçamento mensal separado</p>
                        </button>
                    </div>
                    <div>
                        <p>{acumuladoSelected === "Acumulado" ? titleOptionAcumulado : titleOptionMensal}</p>
                        {acumuladoSelected === "Acumulado" ? subtitleOptionAcumulado : subtitleOptionMensal}
                    </div>
                </body>

                <footer>
                    <button
                        onClick={fetchChangeBalancePreference}
                        style={acumuladoSelected === totalBalance.preferenciaSaldo ? disabledButtonBalancePreference : {}}
                        disabled={acumuladoSelected === totalBalance.preferenciaSaldo}
                    >Alterar</button>
                </footer>
            </div>
        </div>
    )


    useEffect(() => {
        setAcumuladoSelected(totalBalance.preferenciaSaldo)
    }, [changingBalance])

    return (
        <>
            {isMobile ? smartphoneTotal : desktopTotal}
            {changingBalance && changeBalanceWindow}
        </>

    )
}

export default TotalBalanceCard;