import { useEffect, useState } from 'react';
import './Dropbox.css'
import useWindowWidth from '../../../../../../../useWindowWidth';

function Dropbox({ data, selectedValue, handleClick, closeFilterWindows, setCloseFilterWindows, maxHeight, onClickOption, onCickAtive }) {
    const [dropboxActive, setDropboxActive] = useState(false)
    const isMobile = useWindowWidth();

    const handleClickOption = (item) => {
        handleClick(item);
        setDropboxActive(false)
    }

    const handleClickContainer = (e) => {
        if(onCickAtive) onClickOption();
        if (selectedValue === closeFilterWindows) {
            setDropboxActive(false);
            return;
        }

        e.stopPropagation();
        setDropboxActive(!dropboxActive)
        setCloseFilterWindows(selectedValue.name);


    }

    const renderSelect = (item, index) => {
        return (
            <div
                key={index}
                onClick={() => handleClickOption(item)}
            >
                {item.name}
            </div>
        );
    }

    useEffect(() => {
        if (closeFilterWindows !== selectedValue?.name) {
            setDropboxActive(false);
        }
    }, [closeFilterWindows])

    return (
        <div className='DropboxContainer' onClick={handleClickContainer}
            style={{ borderColor: selectedValue?.color }}
        >
            <div>
                <div>
                    {selectedValue?.id === 0 && <img src={selectedValue?.img} />}
                    <p>{selectedValue?.name}</p>
                </div>
                <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/arrowdropbox.svg' alt='dropboxArrow' />
            </div>
            {dropboxActive &&
                <div style={{
                    borderColor: selectedValue?.color,
                    maxHeight: (!isMobile && maxHeight) && maxHeight 
                }}>
                    {Object.values(data).map((item, index) => renderSelect(item, index))}
                </div>
            }
        </div>
    )
}

export default Dropbox;