import './HeaderSummary.css'
import ChangeMonthComponent from '../DashboardViews/ResumoView/ResumoViewCards/ChangeMonthComponent';
import { useFinancialContext } from '../../../FinancialContext';
import { useEffect, useState } from 'react';
import useWindowWidth from '../../../useWindowWidth';
import UpdateWindow from './UpdateWindow';

function HeaderSummary({ viewClicked, handleViewClick, setGenericComponentData }) {
    const { userFirstName, userProfileImage, hideFinances, setHideFinances, messages } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [imageUrl, setImageUrl] = useState(userProfileImage ? `data:image/jpeg;base64,${userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg');
    const [newMessage, setNewMessage] = useState(false);
    const [shake, setShake] = useState(false);

    const handleClickOpenUpdateView = (height, width) => {
        setGenericComponentData({
            title: 'Atualizações',
            style: {
                height: height,
                width: width,
            },
            data: <UpdateWindow />,
        })
    }

    const headerDesktop = (
        <header className='HeaderSummaryDashboard'>
            <div className='UserDetailsImageProfile' onClick={() => handleViewClick("Meu perfil")}>
                <div className='user-profile-image-overflow'>
                    <img src={imageUrl} />
                </div>
                <div >
                    <p>Boas vindas,</p>
                    <p>{userFirstName.length <= 25 ? userFirstName : `${userFirstName.slice(0, 25)}..`}.</p>
                </div>
            </div>

            <section className='header-dashboard-view'>
                <div
                    onClick={() => handleClickOpenUpdateView('80%', '50%')}
                    className='notificationsButton'
                    style={{
                        animation: shake && 'shake 0.5s infinite'
                    }}
                >
                    <img src='/Images_Dashboard_Summary/Header/newNotification.svg' />
                    {/*bolinha vermelha de notificação*/}
                    {newMessage && <div><div /></div>}
                </div>
                <ChangeMonthComponent
                    userClickedOutOfWindow={viewClicked}
                />
            </section>

        </header>
    )

    const headerSmartphone = (
        <header className='HeaderSummaryDashboardSmartphone'>
            <section>
                <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' onClick={() => handleViewClick("Finanças")} />
                <div>
                    <div
                        onClick={() => handleClickOpenUpdateView('60%', '90%')}
                        className={`notificationsButton ${shake && 'shakenotification'}`}
                    >
                        <img src='/Images_Dashboard_Summary/Header/newNotification.svg' />
                        {/*bolinha vermelha de notificação*/}
                        {newMessage && <div><div /></div>}
                    </div>
                    <div>
                        <img src={imageUrl} onClick={() => handleViewClick("Meu perfil")} />
                    </div>
                </div>
            </section>
            <div>
                <ChangeMonthComponent
                    userClickedOutOfWindow={viewClicked}
                />
            </div>
        </header>
    )

    useEffect(() => {
        setImageUrl(userProfileImage ? `data:image/jpeg;base64,${userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg')
    }, [userProfileImage])

    useEffect(() => {
        if (messages) {
            const hasUnreadMessages = messages.some(message => !message.read);
            setTimeout(() => {
                setNewMessage(hasUnreadMessages);
                if (!hasUnreadMessages) return;

                setShake(true);
                setTimeout(() => {
                    setShake(false);
                }, 500);
            }, 500);
        } else {
            setShake(false);
            setNewMessage(false);
        }
    }, [messages]);
    return (
        <>
            {isMobile ? headerSmartphone : headerDesktop}
        </>
    )

}

export default HeaderSummary;