import './EditCategoriesWindow.css'
import { useState } from 'react';
import { spendCategoriesColor, incomeCategoriesColor } from '../../../../../../../staticData';
import { showAlertBox } from '../../../../../../../staticData';
import { useFinancialContext } from '../../../../../../../FinancialContext';
import axios from 'axios';
import useWindowWidth from '../../../../../../../useWindowWidth';

function EditCategoriesWindow({ type }) {
    const { axiosURL, updateDeleteCreateGroups, spendCategoriesData, incomeCategoriesData } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [cardInEdit, setCategorieInEdit] = useState(null);
    const [creatingNewCategorie, setCreatingNewCategorie] = useState(false);

    const [categorieInEditName, setCategorieInEditName] = useState('')
    const [categorieInEditColor, setCategorieInEditColor] = useState('')

    const [newCategorieName, setNewCategorieName] = useState('');
    const [newCategorieColor, setNewCategorieColor] = useState();

    const [shake, setShake] = useState(false);
 
    const handleStopPropagation = (e) => {
        e.stopPropagation()
    }
    const handleTypeName = (e) => {
        setCategorieInEditName(e.target.value)
    }
    const handleClickCategorie = (categorie) => {
        if (cardInEdit && cardInEdit.id === categorie.id) {
            setCategorieInEdit(null);
            setCategorieInEditName('');
            setCategorieInEditColor('');
            return;
        }
        setCategorieInEdit(categorie);
        setCategorieInEditName(categorie.nomeCategoria);
        setCategorieInEditColor(categorie.categoriaCor);
    }
    const renderColorSelector = () => {
        const array = type === 'income' ? incomeCategoriesColor : spendCategoriesColor;
        let colorToCompare = creatingNewCategorie ? newCategorieColor : categorieInEditColor;

        return (
            <div className='color-selector-categorie-edit-window'>
                {array.map(item =>
                    <div
                        onClick={() => {
                            creatingNewCategorie ? setNewCategorieColor(item) : setCategorieInEditColor(item);
                        }}
                        style={{
                            backgroundColor: item,
                            border: colorToCompare === item ? 'solid 0.1vw #242424' : ''
                        }}
                    />
                )}
            </div>
        )
    }
    const handleClearAllData = () => {
        setCreatingNewCategorie(false);
        setNewCategorieName("")
        setNewCategorieColor('');

        setCategorieInEditName("");
        setCategorieInEditColor("");
    }
    const renderEditCard = () => {
        return (
            <div className='categorie-rendered-in-edit-selected'>
                <body>
                    <div>
                        <label>Nome da categoria</label>
                        <input
                            type='text'
                            maxLength={50}
                            value={categorieInEditName}
                            onChange={handleTypeName}
                            onClick={handleStopPropagation}
                        />
                    </div>
                    <div>
                        <div onClick={handleStopPropagation}>
                            <label>Cor da categoria</label>
                            {renderColorSelector()}
                        </div>
                    </div>
                </body>
                <footer>
                    <p onClick={fetchUpdateCategorie}>Confirmar alteração</p>
                    <p onClick={fetchDeleteCategorie}>Excluir categoria</p>
                </footer>
            </div>
        )
    }

    const handleClickCard = (categorie) => {
        if (!categorie.categoriaPadrao) {
            handleClickCategorie(categorie);
        } else {
            showAlertBox('info', 'Você não pode editar esta categoria.');
            setShake(categorie);
            setTimeout(() => {
                setShake(null);
            },  200);
        }
    }

    const renderCard = (categorie) => {
        let cardHeight = cardInEdit && cardInEdit.id === categorie.id ? '11vw' : '4vw'
        if(isMobile){
            cardHeight = cardInEdit && cardInEdit.id === categorie.id ? '50vw' : '13vw'
        }
        const isShaking = shake?.id === categorie.id;

        return (
            <div
                className="categorie-rendered-in-edit-view"
                style={{
                    minHeight: cardHeight,
                    backgroundColor: cardInEdit && cardInEdit.id === categorie.id && `#24242408`,
                    cursor: (cardInEdit && cardInEdit.id === categorie.id) ? 'default' : 'pointer',
                    animation: isShaking && 'shake 0.2s ease'
                }}
                onClick={() => handleClickCard(categorie)}
            >
                <div>
                    <div>
                        <div style={{ backgroundColor: categorie.categoriaCor }}>
                            {categorie.categoriaIcone && <img src={categorie.categoriaIcone} />}
                        </div>
                        <div>
                            <p>{categorie.nomeCategoria}</p>
                        </div>
                    </div>
                </div>
                {(cardInEdit && cardInEdit.id === categorie.id) && renderEditCard(categorie)}
            </div>
        )
    }

    const fetchDeleteCategorie = async () => {
        if (!cardInEdit) return;

        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.delete(`${axiosURL}/financialGroup/${cardInEdit.id}`, config);

            if (response.status === 204) {
                updateDeleteCreateGroups("delete", cardInEdit.id, type);
                showAlertBox('success', 'Categoria deletada com sucesso!');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchUpdateCategorie = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const data = {
                id: cardInEdit.id,
                nomeCategoria: categorieInEditName,
                categoriaCor: categorieInEditColor
            }

            const response = await axios.put(`${axiosURL}/financialGroup`, data, config);

            if (response.status === 200) {
                updateDeleteCreateGroups("update", response.data, type);
                showAlertBox('success', 'Categoria alterada com sucesso!');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCreateNewCategorie = async () => {
        try {
            if (!newCategorieName) {
                showAlertBox('error', 'Informe o nome da categoria!');
            } else if (!newCategorieColor) {
                showAlertBox('error', 'Selecione a cor da categoria!');
            }

            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            };

            const newCategorie = {
                financialGroup: newCategorieName,
                financialType: type,
                categorieColor: newCategorieColor
            }

            const response = await axios.post(`${axiosURL}/financialGroup`, newCategorie, config);

            if (response.status === 201) {
                updateDeleteCreateGroups("create", response.data, type);
                showAlertBox('success', 'Categoria criada com sucesso!');
                handleClearAllData();
            }
        } catch (error) {
            console.log(error);
        }
    };


    const renderCreatingNewCategorie = () => {
        return (
            <>
                <div className='creating-new-categorie-overlay' onClick={handleClearAllData} />
                <div className='create-new-categorie-container'>
                    <header>
                        <p>Crie uma nova categoria</p>
                    </header>
                    <footer>
                        <div>
                            <div>
                                <label>Nome da categoria</label>
                                <input
                                    type='text'
                                    maxLength={50}
                                    value={newCategorieName}
                                    onChange={(e) => setNewCategorieName(e.target.value)}
                                />
                            </div>
                            <div>
                                <label>Cor da categoria</label>
                                {renderColorSelector()}
                            </div>
                        </div>
                        <div>
                            <p onClick={handleClearAllData}>Cancelar</p>
                            <button onClick={fetchCreateNewCategorie}>Criar categoria</button>
                        </div>
                    </footer>
                </div>
            </>
        )
    }

    const renderFooter = () => {
        return (
            <footer>
                <button onClick={() => {
                    setCreatingNewCategorie(!creatingNewCategorie);
                    setCategorieInEdit(null)
                }}>Cadastrar nova categoria</button>
            </footer>
        )
    }



    return (
        <div className='edit-categories-open-window-container'>
            <body>
                {(() => {
                    const data = type === 'income' ? incomeCategoriesData : spendCategoriesData;

                    const sortedArray = data.sort((a, b) => {
                        if (a.categoriaPadrao === b.categoriaPadrao) {
                            return 0; 
                        }
                        return a.categoriaPadrao ? 1 : -1; 
                    });

                    return sortedArray && Object.values(sortedArray).map((categories) => renderCard(categories));
                })()}
            </body>
            {creatingNewCategorie ? renderCreatingNewCategorie() : renderFooter()}
        </div>
    );
}

export default EditCategoriesWindow;