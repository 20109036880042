import './BankSelector.css'
import { useState } from 'react';

function BankSelector({values, handleClickParent}) {
    const [isHovered, setIsHovered] = useState(false);
    const [clicked, setClicked] = useState(null);

    const handleMouseEnter = (value) => {
        setIsHovered(value);
    };
    const handleMouseLeave = () => {
        setIsHovered(null);
    };

    const handleClick = (value) => {
        setClicked(value)
        handleClickParent(value)
    }




    const renderBank = (value) => {
        const backgroundActive = (isHovered && value.id === isHovered.id) || (clicked && value.id === clicked.id)

        return (
            <div className='bank-selector-button'
                style={{ backgroundColor: backgroundActive && value.cor+'1A'}}
                onMouseEnter={() => handleMouseEnter(value)}
                onMouseLeave={() => handleMouseLeave(value)}
                onClick={() => handleClick(value)}
            >
                <img src={value.urlImagem} />
                <p>{value.nome}</p>
            </div>
        )
    }


    if (!values) return;
    return (
        <div className='bank-selector-container'>
            {values.map(value => renderBank(value))}
        </div>
    )
}

export default BankSelector;