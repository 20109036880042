import { Flip, toast } from "react-toastify";

export const spendCategoriesColor = [
    "#ED2A0E", 
    "#ED780E", 
    "#F52245", 
    "#ED510E", 
    "#ED9B0E", 
    "#F57842", 
    "#81582A", 
    "#F05231", 
    "#DB0904", 
    "#F55850"
]
export const incomeCategoriesColor = [
    "#0DE02C", 
    "#2DEBC9", 
    "#00F576", 
    "#00D0F5", 
    "#3AF000", 
    "#2FF590", 
    "#3B7543", 
    "#3B756B", 
    "#49753B", 
    "#3B6C75"
]


export const showAlertBox = (type, message, duration) => {
    const toastConfig = {
        position: "bottom-right",
        autoClose: duration ? duration : 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Flip
    };

    switch (type) {
        case "success":
            toast.success(message, toastConfig);
            break;
        case "error":
            toast.error(message, toastConfig);
            break;
        case "warning":
            toast.warning(message, toastConfig);
            break;
        case "info":
            toast.info(message, toastConfig);
            break;
    }
};

