import './FinanceCardOpenView.css'
import React, { useEffect, useState } from 'react';
import { format, parseISO, differenceInMonths, startOfMonth } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import useWindowWidth from '../../../../../../../useWindowWidth';

function FinanceCardOpenView({ financeCard, type, monthNumber, yearSelected,
    handleClickedFinance, clickedFinance, setClickedFinance, getDaysOfMonth,
    handleCheckFinance }) {

    const isMobile = useWindowWidth();

    const [cardSelected, setCardSelected] = useState(false);
    const [checkBoxChecked, setCheckBoxChecked] = useState(financeCard.pagaOuRecebida);

    const [textPaidReceived, setTextPaidReceived] = useState('')

    //Função que formata a data
    function formatFinancialDate(fullFinancialDate, date) {
        let formattedByDayDate;

        if (date) {
            let date1Parts = date.split("-");
            let date2Parts = financeCard.data.split("-");
            formattedByDayDate = `${date1Parts[0]}-${date1Parts[1]}-${date2Parts[2]}`;
        }

        let dateToFormat;
        let financeDate = formattedByDayDate ? formattedByDayDate : financeCard.data;
        dateToFormat = parseISO(financeDate);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(dateToFormat, userTimeZone);

        const lastDayInMonth = getDaysOfMonth().slice(-1)[0];
        const financeDay = zonedDate.getDate();
        let day = zonedDate.getDate();

        if (financeDay > lastDayInMonth || financeDay === 0) {
            day = lastDayInMonth;
        }

        if (fullFinancialDate === 'year') return format(zonedDate, `dd/MM/yyyy`);
        if (fullFinancialDate === 'month') return `${day}/${monthNumber}`;
        return day;
    }
    //Função que calcula o fim da recorrência, para ficar por exemplo: 1 de 5
    const calculateRecurrenceInfo = () => {
        if (financeCard.fimRecorrencia) {
            const selectedDate = parseISO(`${yearSelected}-${monthNumber.toString().padStart(2, '0')}-01`)
            const finalDateRecurrence = parseISO(financeCard.fimRecorrencia);
            const financeDate = financeCard.specific ? parseISO(financeCard.originalFinanceDate) : parseISO(financeCard.data);
            financeDate.setDate(15);

            const monthsDifferenceTotal =
                format(finalDateRecurrence, 'MM/yyyy') === format(financeDate, 'MM/yyyy') ? 1
                    : differenceInMonths(finalDateRecurrence, startOfMonth(financeDate)) + 1;

            const monthsDifferenceNow =
                format(selectedDate, 'MM/yyyy') === format(financeDate, 'MM/yyyy') ? 1
                    : differenceInMonths(selectedDate, financeDate) + 2;

            return (
                <>
                    {monthsDifferenceNow} de {monthsDifferenceTotal}
                </>
            );
        }
    };

    const recurrenceInfo = () => {
        if (financeCard.fimRecorrencia) return "Parcelado";
        if (financeCard.recorrente) return "Recorrente";
        return "Não recorrente";
    }

    const renderPaidReceivedText = () => {
        let text = "";

        if (type === "income") {
            text = financeCard.pagaOuRecebida ? "Recebida" : "Não recebida";
        } else if (type === "spend") {
            text = financeCard.pagaOuRecebida ? "Paga" : "Não paga";
        }

        setTextPaidReceived(text);
    }

    const handleInputClick = async (e) => {
        e.stopPropagation();
        const prevState = checkBoxChecked;
        setCheckBoxChecked(!checkBoxChecked);
        const finance = {
            ...financeCard,
            pagaOuRecebida: !checkBoxChecked
        }
        const hadError = await handleCheckFinance(finance, !checkBoxChecked);

        if (hadError) {//Voltando ao estado anterior
            if(clickedFinance && clickedFinance.id === financeCard.id){
                setClickedFinance({
                    ...clickedFinance,
                    pagaOuRecebida: !checkBoxChecked
                })
            }
            setCheckBoxChecked(prevState);
        }
    };

    const financialCardNote = (type, classCSS, showImg) => {

        let paddingAdjust = {};

        let text;
        let color;
        let img;

        if (type === 'origin') {

            if (financeCard.creditCard) {
                
                paddingAdjust = (isMobile && classCSS === 'HeaderNoteFinancialCardEdit') ? {
                    paddingRight: '4vw'
                } : {};

                color = financeCard.creditCard.account.banks.corBanco;
                if (financeCard.creditCard.account.banks.type === 'BANK') {
                    text = "Crédito " + financeCard.creditCard.nome_cartao.replace("Cartão", '');
                } else if (financeCard.creditCard.account.banks.type === 'BENEFITS') {
                    text = financeCard.creditCard.nome_cartao;
                }
                img = <img src={financeCard.creditCard.account.banks.urlImagem} />

            } else if (financeCard.origemFinanca && !financeCard.creditCard) {

                paddingAdjust = (isMobile && classCSS === 'HeaderNoteFinancialCardEdit') ? {
                    paddingRight: '4vw'
                } : {};

                switch (financeCard.origemFinanca) {
                    case 'TRANSFERENCIA':
                        color = '#0848BF';
                        img = <img src={'/Images_Dashboard_Summary/DefaultIcons/FilterIcons/transferIcon.svg'} />
                        text = 'Transferência';
                        break;
                    case 'PIX':
                        color = '#08BFB1';
                        img = <img src={'/Images_Dashboard_Summary/DefaultIcons/FilterIcons/pixIcon.svg'} />
                        text = 'Pix';
                        break;
                    case 'CARTAO_DE_DEBITO':
                        color = '#843031';
                        img = <img src={'/Images_Dashboard_Summary/DefaultIcons/FilterIcons/debitCardIcon.svg'} />
                        text = 'Cartão de débito';
                        break;
                    case 'BOLETO':
                        color = '#242424';
                        img = <img src={'/Images_Dashboard_Summary/DefaultIcons/FilterIcons/boletoIcon.svg'} />
                        text = 'Boleto';
                        break;
                }
            }
        }
        if (type === 'categorie') {
            if (!financeCard.categoria) return;
            color = financeCard.categoria?.categoriaCor;
            text = financeCard.categoria?.nomeCategoria;
            img =
                <div style={{ backgroundColor: financeCard.categoria.categoriaCor, borderRadius: !financeCard.categoria.categoriaIcone && '50%' }}>
                    {financeCard.categoria.categoriaIcone && <img src={financeCard.categoria.categoriaIcone} />}
                </div>
        }

        if (type === 'recurrence') {
            text = recurrenceInfo();

            switch (text) {
                case 'Parcelado':
                    color = '#294986';
                    img = <img src={'/Images_Dashboard_Summary/DefaultIcons/RecurrenceWithLimitIcon.svg'} />
                    break;
                case 'Recorrente':
                    color = '#EEC842';
                    img = <img src={'/Images_Dashboard_Summary/DefaultIcons/RecurrenceFinanceIcon.svg'} />
                    break;
                case 'Não recorrente':
                    color = '#682D3B';
                    img = <img src='/Images_Dashboard_Summary/DefaultIcons/notRecurrenceIcon.svg' />
                    break;
            }

        }

        if (!text) return;

        let note = (
            <div
                className={classCSS}
                style={{
                    ...paddingAdjust,
                    color: color,
                    border: `solid 0.1vw ${color}`,
                    backgroundColor: `${color}14`,
                }}
            >
                {(showImg && img) && img}
                <p>{text}</p>
            </div>
        )

        return note;
    }

    const renderAccountLogo = () => {
        if (!financeCard.account) return;

        return (
            <img src={financeCard.account.banks.urlImagem} />
        )
    }

    const handleCloseOpenCard = (e) => {
        e.preventDefault();
        setCardSelected(false);
        setClickedFinance(null)
    }




    /*Cards desktop*/
    const renderFullFinancialCard = () => {

        const handleClickOpenCard = (e) => {
            e.stopPropagation();
        }

        return (
            <div className='mainContainerFinanancialCardInfoEdit cursorDefault' onClick={handleClickOpenCard}>
                <header className='OpenFinancialCardHeaderEdit'>
                    <div>
                        {financialCardNote('recurrence', 'HeaderNoteFinancialCardEdit', true)}
                        {financeCard.origemFinanca && financialCardNote('origin', 'HeaderNoteFinancialCardEdit', true)}
                        {financialCardNote('categorie', 'HeaderNoteFinancialCardEdit', true)}

                    </div>
                    {renderAccountLogo()}

                </header>
                <section>
                    <p className='BodyFinancialCardOpenView' id='TitleFinancialCardOpenEdit'>{financeCard.nome}</p>
                    <p className='BodyFinancialCardOpenView' id='DateFinancialCardOpenEdit'>Data {formatFinancialDate('month')}</p>
                    {financeCard.recorrente ? <p className='BodyFinancialCardOpenView' id='CreatedDateFinancialCardOpenEdit'>Início em {formatFinancialDate('year', financeCard.originalFinanceDate)}</p> : null}
                    {financeCard.fimRecorrencia ? <p className='BodyFinancialCardOpenView' id='CreatedDateFinancialCardOpenEdit'>Finaliza em {formatFinancialDate('year', financeCard.fimRecorrencia)}</p> : null}
                    <p className='BodyFinancialCardOpenView' id='ObservationsTitleFinancialCardOpenEdit'>Observações</p>
                    <p className='BodyFinancialCardOpenView' id='ObservationsFinancialCardOpenEdit'>{financeCard.observacoes}</p>
                    <p id='RecurrenceWithDateFinancialCardEdit'>{calculateRecurrenceInfo()}</p>
                    <div className='ContainerPaidReceivedEdit'
                        style={{
                            marginTop: financeCard.fimRecorrencia ? '3%' : '14%'
                        }}
                    >
                        <p>
                            {textPaidReceived}
                        </p>
                        <div
                            onClick={handleInputClick}
                        >
                            <input
                                type="checkbox"
                                className="custom-checkbox checkboxOpenCard"
                                checked={checkBoxChecked}
                            />
                        </div>
                    </div>
                </section>
                <footer
                    style={{
                        backgroundColor: type === 'income' ? '#489946B2' : '#ED4C5CB2'
                    }}
                >
                    <p>R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </footer>
            </div>
        )
    }
    const renderShortFinancialCard = () => {
        const handleClickCard = (e) => {
            handleClickedFinance(financeCard);
            setCardSelected(true);
            e.stopPropagation();
        }

        return (
            <div className="fullSizeWidthHeight" onClick={handleClickCard}>
                <div className='mainContainerFinanancialCardInfoView'>
                    <header className='headerShortFinancialCardView'>
                        <div>
                            {financialCardNote('categorie', 'HeaderNoteFinancialCardView', false)}
                            {financeCard.origemFinanca ? financialCardNote('origin', 'HeaderNoteFinancialCardView', false)
                                : financialCardNote('recurrence', 'HeaderNoteFinancialCardView', false)}
                        </div>
                        <div>
                            {renderAccountLogo()}
                        </div>
                    </header>
                    <section>
                        <p className='BodyFinancialCardOpenView' id='TitleFinancialCardOpenView'>{financeCard.nome}</p>
                        <p className='BodyFinancialCardOpenView' id='DateFinancialCardOpenView'>Dia {formatFinancialDate(false)}</p>
                        <p id='RecurrenceWithDateFinancialCard'>{calculateRecurrenceInfo()}</p>
                        <div className='ContainerPaidReceived'
                            style={{
                                marginTop: financeCard.fimRecorrencia ? '10%' : '24%'
                            }}
                        >
                            <p>
                                {textPaidReceived}
                            </p>
                            <div
                                onClick={handleInputClick}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={checkBoxChecked}
                                />
                            </div>
                        </div>
                    </section>
                    <footer>
                        <p>R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </footer>
                </div>
            </div>
        )
    }





    /*Cards smartphone*/
    const renderShortFinancialCardSmartphone = () => {
        const handleClickCard = (e) => {
            handleClickedFinance(financeCard);
            setCardSelected(true);
            e.stopPropagation();
        }

        return (
            <div className="fullOpenSmartphoneCard" onClick={handleClickCard}>
                <header className='headerShortFinancialCardView'>
                    <div>
                        {financialCardNote('origin', 'HeaderNoteFinancialCardView', false)}
                        {financialCardNote('categorie', 'HeaderNoteFinancialCardView', false)}
                    </div>
                    <div>
                        {renderAccountLogo()}
                    </div>
                </header>
                <body>
                    <div>
                        <p>{financeCard.nome}</p>
                        <p>{calculateRecurrenceInfo()}</p>
                    </div>
                    <div>
                        <p
                            style={{ color: type === 'income' ? '#489946' : '#ED4C5C' }}
                        >R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    </div>
                    <div>
                        <p>Dia {formatFinancialDate(false)}</p>
                        <div>
                            <p>
                                {textPaidReceived}
                            </p>
                            <div
                                onClick={handleInputClick}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-checkbox"
                                    checked={checkBoxChecked}
                                />
                            </div>
                        </div>
                    </div>
                </body>
            </div>
        )
    }
    const renderFullFinancialCardSmartphone = () => {
        const handleClickCard = (e) => {
            e.stopPropagation();
        }

        return (
            <div className="smartphoneOpenFinancialCard" onClick={handleClickCard}>
                <header>
                    <div>
                        {renderAccountLogo()}
                        {financialCardNote('origin', 'HeaderNoteFinancialCardEdit', true)}
                        {financialCardNote('creditCard', 'HeaderNoteFinancialCardEdit', true)}
                        {financialCardNote('categorie', 'HeaderNoteFinancialCardEdit', true)}
                    </div>
                    <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/closefinancialcard.png' onClick={handleCloseOpenCard} />
                </header>
                <body>
                    <p>{financeCard.nome}</p>
                    <div>
                        <div>
                            <p>Data {formatFinancialDate(false)}</p>
                            {financeCard.recorrente ? <p>Início em {formatFinancialDate('year', financeCard.originalFinanceDate)}</p> : null}
                            {financeCard.fimRecorrencia ? <p>Finaliza em {formatFinancialDate('year', financeCard.fimRecorrencia)}</p> : null}
                        </div>
                        <p>{calculateRecurrenceInfo()}</p>
                    </div>
                    <div>
                        <p>Observações</p>
                        <p>{financeCard.observacoes}</p>
                    </div>

                </body>
                <footer>
                    <p
                        style={{
                            color: type === 'income' ? '#489946' : '#ED4C5C'
                        }}
                    >R$ {financeCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                    <div>
                        <p>{textPaidReceived}</p>
                        <div onClick={handleInputClick}>
                            <input
                                type="checkbox"
                                className="custom-checkbox checkboxOpenCard"
                                checked={checkBoxChecked}
                            />
                        </div>
                    </div>
                </footer>
            </div >
        )
    }

    useEffect(() => {
        setCheckBoxChecked(financeCard.pagaOuRecebida)
        renderPaidReceivedText();
    }, [financeCard.pagaOuRecebida])

    useEffect(() => {
        renderPaidReceivedText();
    }, [checkBoxChecked])

    return (
        <div
            className={`mainFinanceCardOpenViewShadow ${clickedFinance?.id === financeCard.id ? 'openFinancialCardEdit' : ''}`}
            style={{
                gridColumn: clickedFinance?.id === financeCard.id ? 'span 2' : 'auto',
                gridRow: clickedFinance?.id === financeCard.id ? 'span 2' : 'auto',
            }}
        >
            {cardSelected && clickedFinance?.id === financeCard.id ? (
                !isMobile ? renderFullFinancialCard() : renderFullFinancialCardSmartphone()
            ) : (
                !isMobile ? renderShortFinancialCard() : renderShortFinancialCardSmartphone()
            )}
        </div>
    )
}

export default FinanceCardOpenView;