import "./mainCreateNewUser.css"
import FormsCreateNewUser from "./FormsCreateNewUser";
import React, { useState } from 'react';
import Header from "../Page 1 - LandingPage/Header";
import useViewportHeight from "../../useViewportHeight";

function CreateUserPage() {
    useViewportHeight();

    const [createViaEmail, setCreateViaEmail] = useState();

    return (
        <section className="allGroupCreate">
            <Header hideButton={true} />
            <div>
                <div>
                    <img src="/Images_Login/ImageLoginPage.png" />
                </div>
                <div>
                    <header>
                        <p>Nesse momento sua vida financeira se torna mais organizada. Vamos lá?</p>
                         <p>Preenha as informações com bastente <span>atenção!</span></p>
                    </header>
                    <div>
                        <FormsCreateNewUser />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateUserPage;