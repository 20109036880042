import './AccountEdit.css'
import { useFinancialContext } from '../../../../../../../FinancialContext';
import { useState, useEffect } from 'react';
import BankSelector from '../BankSelector';
import axios from 'axios';
import { showAlertBox } from '../../../../../../../staticData';
import useWindowWidth from '../../../../../../../useWindowWidth';

function AccountEdit({ autoOpenAccount, autoOpenCreateAcconut }) {
    const isMobile = useWindowWidth();
    const { accountsData, axiosURL, updateDeleteCreateAccount, fetchAccountBalanceData, totalBalance } = useFinancialContext();
    const [cardInEdit, setCardInEdit] = useState(null);
    const [balance, setBalance] = useState(null); //Usada para conta em edição
    const [name, setName] = useState(null); //Usada para conta em edição

    const [balanceNewAccount, setBalanceNewAccount] = useState("R$ 00,0");
    const [newAccountBankId, setNewAccountBankId] = useState(0);
    const [newAccountName, setNewAccountName] = useState('');

    const [alertDeleteAccount, setAlertDeleteAccount] = useState(false);

    const [allBanks, setAllBanks] = useState(null);
    const [bankOverBenefits, setBankOverBenefits] = useState(true);

    const [creatingNewAccount, setCreatingNewAccount] = useState(false);

    const handleClickAccount = (account) => {
        setAlertDeleteAccount(false);
        const inEdit = cardInEdit;

        if (inEdit && inEdit === account) {
            setCardInEdit(null);
            return;
        }
        setCardInEdit(account);

        const valueAsNumber = Number(account.accountBalance);
        const valueFormatted = valueAsNumber.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        setBalance("R$ " + valueFormatted)
        setName(account.accountName)
    }
    const handleCreateNewAccount = () => {
        setCreatingNewAccount(!creatingNewAccount);
        setNewAccountBankId(null);
        setBalanceNewAccount("R$ 00,0")
        setNewAccountName('')
    }
    const handleSelectBank = (bank) => {
        setNewAccountBankId(bank.id);
        setNewAccountName(bank.nome);
    }


    const handleStopPropagation = (e) => {
        e.stopPropagation()
    }
    const handleBalance = (e, setter) => {

        let rawValue = e.target.value.replace(/\D/g, '');

        const valueAsNumber = Number(rawValue) / 100;

        const valueFormatted = valueAsNumber.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        setter("R$ " + valueFormatted)
    }


    const fetchGetBanks = async () => {
        const token = localStorage.getItem("SnnAcss");
        const header = {
            Authorization: `Bearer ${token}`,
        }
        try {
            const response = await axios.get(`${axiosURL}/account/getBanks`, { headers: header })
            if (response.status === 200) {

                const banks = response.data.map(value => ({
                    id: value.id,
                    cor: value.corBanco,
                    nome: value.nomeBanco,
                    urlImagem: value.urlImagem,
                    type: value.type
                }))
                setAllBanks(banks);
            }
        } catch (e) {

        }
    }
    const fetchDeleteAccount = async (e) => {

        if (!alertDeleteAccount) {
            e.stopPropagation();
            setAlertDeleteAccount(true);
            showAlertBox('warning', 'Ao excluir uma conta, cartões vinculados serão excluídos e vínculos com gastos e receitas desfeitos. Clique novamente para confirmar.', 7000);
            return;
        }
        const token = localStorage.getItem("SnnAcss");
        const header = {
            Authorization: `Bearer ${token}`,
        }

        try {
            const response = await axios.delete(`${axiosURL}/account/${cardInEdit.id}`, { headers: header })
            if (response.status === 204) {
                updateDeleteCreateAccount("delete", cardInEdit.id);
                showAlertBox('success', 'Conta deletada com sucesso!');
                fetchAccountBalanceData();
            }
            setAlertDeleteAccount(false);
        } catch (e) {
            setAlertDeleteAccount(false);
            showAlertBox('error', 'Ocorreu um erro.');
        }
    }
    const fetchCreateNewAccount = async () => {
        if (!newAccountBankId) {
            showAlertBox('error', 'Selecione o banco.');
        }
        if (!newAccountName) {
            showAlertBox('error', 'Informe o nome da conta.');
        }

        const token = localStorage.getItem("SnnAcss");
        const header = {
            Authorization: `Bearer ${token}`,
        }

        const formattedBalance = parseFloat(balanceNewAccount.replace('R$', '').replace(/\./g, '').replace(',', '.'));

        const data = {
            bank_id: newAccountBankId,
            account_value: formattedBalance,
            account_name: newAccountName
        }

        try {
            const response = await axios.post(`${axiosURL}/account`, data, { headers: header })

            if (response.status === 200) {
                updateDeleteCreateAccount('create', response.data);
                handleCreateNewAccount();
                showAlertBox('success', 'Conta criada com sucesso!');
                fetchAccountBalanceData();
            }
        } catch (e) {
            console.log(e)
        }

    }
    const fetchUpdateAccount = async () => {
        if (!name) {
            showAlertBox('error', 'O nome é obrigatório.');
            return;
        }

        const token = localStorage.getItem("SnnAcss");
        const header = {
            Authorization: `Bearer ${token}`,
        }

        const formattedBalance = parseFloat(balance.replace('R$', '').replace(/\./g, '').replace(',', '.'));

        const data = {
            account_id: cardInEdit.id,
            account_value: formattedBalance,
            account_name: name
        }

        try {
            const response = await axios.put(`${axiosURL}/account`, data, { headers: header })

            if (response.status === 200) {
                updateDeleteCreateAccount('update', response.data);
                showAlertBox('success', 'Conta alterada com sucesso!');
                fetchAccountBalanceData();
            }
        } catch (e) {
            console.log(e)
        }
    }



    const renderEditCard = () => {

        return (
            <div className='account-card-in-edit-view'>
                <div>
                    <div>
                        <label>Nome da conta</label>
                        <input type='text' onClick={handleStopPropagation} onChange={(e) => setName(e.target.value)} value={name} maxLength={100} />
                    </div>
                    {totalBalance.preferenciaSaldo === "Acumulado" &&
                        <div>
                            <label>Saldo da conta</label>
                            <input type='text' onClick={handleStopPropagation} onChange={(e) => handleBalance(e, setBalance)} value={balance} inputMode="numeric" maxLength={16} />
                        </div>
                    }
                </div>
                <div>
                    <p onClick={fetchUpdateAccount}>Confirmar alteração</p>
                    <p onClick={fetchDeleteAccount}>{alertDeleteAccount ? 'Confirmar exclusão' : 'Excluir conta'}</p>
                </div>
            </div>
        )
    }
    const renderCard = (account) => {
        let cardHeight = cardInEdit && cardInEdit.id === account.id ? '10vw' : '4.5vw'
        if(isMobile){
            cardHeight = cardInEdit && cardInEdit.id === account.id ? '50vw' : '20vw'
        }
        const saldo = "R$ " + account.accountBalance.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


        return (
            <div
                className='account-card-rendered-view'
                style={{
                    minHeight: cardHeight,
                    backgroundColor: cardInEdit && cardInEdit.id === account.id && `#24242408`,
                    cursor: (cardInEdit && cardInEdit.id === account.id) ? 'default' : 'pointer'
                }}
                onClick={() => handleClickAccount(account)}
            >
                <div>
                    <div>
                        <img src={account.banks.urlImagem} />
                        <p>Conta {account.accountName}</p>
                    </div>
                    <p>{saldo}</p>
                </div>

                {(cardInEdit && cardInEdit.id === account.id) && renderEditCard(account)}
            </div>
        )
    }
    const renderFooter = () => {
        return (
            <footer>
                <button onClick={handleCreateNewAccount}>Cadastrar nova conta</button>
            </footer>
        )
    }
    const renderCreateNewAccount = () => {

        const banks = allBanks && allBanks.filter(bank => bank.type === 'BANK');
        const benefits = allBanks && allBanks.filter(bank => bank.type === 'BENEFITS');

        const selectedButtonStyle = {
            backgroundColor: '#EEC842',
            color: '#fafafa',
            fontWeight: '600',
            boxShadow: '#EEC842 0 0.4vw 0.7vw -0.4vw'
        }

        return (
            <>
                <div className='creating-new-account-overlay' onClick={handleCreateNewAccount} />
                <div className='create-new-account-container'>
                    <header>
                        <button style={bankOverBenefits ? selectedButtonStyle : {}} onClick={() => setBankOverBenefits(true)}>Bancos</button>
                        <button style={!bankOverBenefits ? selectedButtonStyle : {}} onClick={() => setBankOverBenefits(false)}>Benefícios</button>
                    </header>
                    <div>                        
                        {(banks && bankOverBenefits) && <BankSelector values={banks} handleClickParent={handleSelectBank} />}
                        {(benefits && !bankOverBenefits) && <BankSelector values={benefits} handleClickParent={handleSelectBank} />}
                    </div>
                    <footer>
                        <div>
                            <div>
                                <label>Nome da conta</label>
                                <input type='text' maxLength={100} value={newAccountName} onChange={(e) => setNewAccountName(e.target.value)} />
                            </div>
                            {totalBalance.preferenciaSaldo === "Acumulado" &&
                                <div>
                                    <label>Saldo da conta</label>
                                    <input type='text' inputMode="numeric" maxLength={16} value={balanceNewAccount} onChange={(e) => handleBalance(e, setBalanceNewAccount)} />
                                </div>
                            }
                        </div>
                        <div>
                            <p onClick={handleCreateNewAccount}>Cancelar</p>
                            <button onClick={fetchCreateNewAccount}>Criar conta</button>
                        </div>
                    </footer>
                </div>
            </>
        )
    }



    useEffect(() => {
        fetchGetBanks();
    }, [])

    useEffect(() => {
        if (autoOpenAccount) {
            handleClickAccount(autoOpenAccount);
        }
    }, [autoOpenAccount])

    useEffect(() => {
        if (autoOpenCreateAcconut) {
            setCreatingNewAccount(true);
        }
    }, [autoOpenCreateAcconut])

    return (
        <div className='account-edit-container'>
            <body>
                {accountsData && Object.values(accountsData).map(account => renderCard(account))}
            </body>
            {creatingNewAccount ? renderCreateNewAccount() : renderFooter()}
        </div>
    )
}

export default AccountEdit;