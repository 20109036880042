import './MainSection.css';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import HeaderSummary from './DashboardHeader'
import DashboardSideBar from './DashboardSideBar';
import ResumoView from './DashboardViews/ResumoView'
import EstatisticasView from './DashboardViews/EstatisticasView'
import { useFinancialContext } from '../../FinancialContext';
import useViewportHeight from '../../useViewportHeight';
import EditProfile from './DashboardViews/EditProfile';
import MessageView from './DashboardViews/MessageView';
import EditWindow from './DashboardViews/ResumoView/ResumoViewCards/EditWindow'
import useWindowWidth from '../../useWindowWidth';
import GenericOpenWindow from './DashboardViews/ResumoView/ResumoViewCards/GenericComponents/GenericOpenWindow';

function FinancialDashboard() {
    const targetDivRef = useRef(null);
    const targetDivRefHome = useRef(null);
    const isMobile = useWindowWidth();

    useViewportHeight();
    const navigate = useNavigate();

    const { userLooged, selectedView, setSelectedView, scrollAvailable } = useFinancialContext();

    const dashboardContainer = useRef(null);

    const [viewClicked, setViewClicked] = useState(false);

    const [openFormsCreateNewFinance, setOpenFormsCreateNewFinance] = useState(false); //Hook para criação de finança direto do click da bottom bar no smartphone

    const [spendCardSelected, setSpendCardSelected] = useState(false);//Hook que verifica se o card foi clicado, para abrir o card grande
    const [incomeCardSelected, setIncomeCardSelected] = useState(false);//Hook que verifica se o card foi clicado, para abrir o card grande

    const [genericComponentData, setGenericComponentData] = useState(null);

    const openFullCard = (type) => {
        if (type === "spend") setSpendCardSelected(true);
        else setIncomeCardSelected(true);
    };
    const handleClickScrollEstatistics = (scrollHeight) => {
        if (targetDivRef.current) {
            targetDivRef.current.scrollTo({
                top: scrollHeight,
                behavior: "smooth",
            });
        }
    };
    const handleClickScrollHome = (scrollHeight) => {
        if (targetDivRefHome.current) {
            targetDivRefHome.current.scrollTo({
                top: scrollHeight,
                behavior: "smooth",
            });
        }
    };
    const allViews = {
        resumo: {
            title: 'Finanças',
            img: '/Images_Dashboard_Summary/SideBar/financa.png',
        },
        estatisticas: {
            title: 'Estatísticas',
            img: '/Images_Dashboard_Summary/SideBar/estatisticas.png'
        },
        config: {
            title: 'Meu perfil',
            img: '/Images_Dashboard_Summary/SideBar/profileSideBar.svg',
        },
        suporte: {
            title: 'Suporte',
            img: '/Images_Dashboard_Summary/SideBar/MessageIcon.svg',
        }
    }
    const handleClickView = () => {
        setViewClicked(true);
        setTimeout(() => {
            setViewClicked(false);
        }, 50)
    }
    const handleSelectView = (view) => {
        setSelectedView(view);
    }
    const renderHeader = () => {
        if (selectedView === 'Meu perfil' || selectedView === 'Suporte') return;

        return (
            <header>
                <HeaderSummary
                    viewClicked={viewClicked}
                    handleViewClick={(view) => handleSelectView(view)}
                    setGenericComponentData={setGenericComponentData}
                />
            </header>
        )
    }
    const renderBody = () => {
        return (
            <section
                onClick={handleClickView}
                ref={dashboardContainer}
                style={{
                    marginTop: isMobile ? ((selectedView === 'Meu perfil' || selectedView === 'Suporte') ? '0' : '15vh') : '0',
                }}
            >

                {selectedView === 'Finanças' &&
                    <ResumoView
                        setSelectedView={setSelectedView}
                        viewClicked={viewClicked}
                        openFormsCreateNewFinance={openFormsCreateNewFinance}
                        setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                        openFullCard={openFullCard}
                        spendCardSelected={spendCardSelected}
                        incomeCardSelected={incomeCardSelected}
                        setSpendCardSelected={setSpendCardSelected}
                        setIncomeCardSelected={setIncomeCardSelected}
                        targetDivRefHome={targetDivRefHome}
                    />
                }
                {selectedView === 'Estatísticas' && <EstatisticasView viewClicked={viewClicked} targetDivRef={targetDivRef} />}
                {selectedView === 'Meu perfil' && <EditProfile viewClicked={viewClicked} setSelectedView={setSelectedView} />}
                {selectedView === 'Suporte' && <MessageView viewClicked={viewClicked} />}
                {selectedView === 'Investimentos' && navigate("/investimentos")}
            </section>
        )
    }

    if (userLooged) {
        return (
            <div className="containerDashboard" ref={targetDivRefHome} style={{ overflow: scrollAvailable ? 'auto' : 'hidden' }}>
                <aside className="sideBarSmartPhone">
                    <DashboardSideBar
                        selectedView={selectedView}
                        handleViewClick={(view) => handleSelectView(view)}
                        allViews={allViews}
                        setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                        openFullCard={openFullCard}
                        dashboardContainer={dashboardContainer}
                        viewClicked={viewClicked}
                        handleClickScrollEstatistics={handleClickScrollEstatistics}
                        handleClickScrollHome={handleClickScrollHome}
                    />
                </aside>

                <div
                    onClick={handleClickView}
                >
                    {renderHeader()}
                    {renderBody()}
                </div>
                <EditWindow />
                {genericComponentData && <GenericOpenWindow children={genericComponentData} closeGenericComponent={setGenericComponentData}/>}
            </div>
        )
    } else {
        navigate("/login")
    }
}

export default FinancialDashboard;
