import React from 'react';
import './GenericOpenWindow.css';
import UpdateWindow from '../../../../../DashboardHeader/UpdateWindow';

const GenericOpenWindow = ({ children, closeGenericComponent }) => {
    return (
        <div className="overlay-generic-window" onClick={() => closeGenericComponent(null)}>
            <div
                className="content-generic-window"
                onClick={(e) => e.stopPropagation()}
                style={children.style}
            >
                <header>
                    <h1>{children.title}</h1>
                </header>
                <body>
                    {/*children.data*/}
                    <UpdateWindow/>
                </body>
            </div>
        </div>
    );
};

export default GenericOpenWindow;