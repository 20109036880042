import "./PorcentagemCategoriasHistorico.css";
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useFinancialContext } from "../../../../../../FinancialContext";
import useWindowWidth from "../../../../../../useWindowWidth";
import format from "date-fns-tz/format";
import { parseISO } from "date-fns";

function PorcentagemCategoriasHistorico({ type }) {
    const { hideFinances, incomeCategoriesHistory, spendCategoriesHistory } = useFinancialContext();
    const isMobile = useWindowWidth();

    const chartReference = useRef(null);
    const canvasRef = useRef(null);

    const title = type === 'income' ? "Categorias das suas receitas" : "Categorias dos seus gastos";

    const renderChart = (months, chartData, isMobile) => {
        const ctx = canvasRef.current.getContext('2d');
        const chartRef = chartReference;

        if (chartRef.current) {
            chartRef.current.destroy();
        }

        const data = {
            labels: months,
            datasets: !hideFinances ? chartData : null
        };

        // Definindo opções específicas para desktop e smartphone
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    },
                    onHover: function (event) {
                        event.native.target.style.cursor = 'pointer';
                    },
                    onLeave: function (event) {
                        event.native.target.style.cursor = 'default';
                    }
                },
                datalabels: {
                    anchor: 'center',
                    align: 'center',
                    color: '#444',
                    formatter: (value) => {
                        return "R$ " + Math.round(value);
                    },
                    font: {
                        family: 'Jost',
                        size: 12,
                        color: '#333333'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Mês',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    },
                    reverse: true
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Valor',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    }
                },
            },
            layout: {
                padding: {
                    right: 15,
                    left: 0,
                    bottom: 10
                }
            },
        };

        chartRef.current = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
            plugins: [ChartDataLabels],
        });

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    };


    useEffect(() => {
        const data = type === 'income' ? incomeCategoriesHistory : spendCategoriesHistory;

        if (data) {

            let months = Object.keys(data);

            if (isMobile) months.splice(3)

            const categories = {};

            months.forEach(month => {
                Object.values(data[month]).forEach(category => {
                    if (!categories[category.nomeCategoria]) {
                        categories[category.nomeCategoria] = {
                            label: category.nomeCategoria,
                            data: months.map(() => 0),
                            backgroundColor: category.categoriaCor + 'B3'
                        };
                    }

                    const monthIndex = months.indexOf(month);
                    categories[category.nomeCategoria].data[monthIndex] = category.categorieValue;
                });
            });

            const chartData = Object.values(categories);
            const monthsFormatted = months.map(date => format(parseISO(date), 'MM/yyyy'));

            renderChart(monthsFormatted, chartData);
        }
    }, [hideFinances, spendCategoriesHistory, incomeCategoriesHistory, isMobile]);

    return (
        <div className='FinancialCardSummaryContainer PorcentagemCategoriasHistoricoContainer'>
            <>
                <header>
                    <div>
                        <p>{title}</p>
                        <p><img src='/Images_Dashboard_Summary/EstatisticsView/infoIcon.svg' />Clique na legenda da categoria para filtrá-la</p>
                    </div>
                </header>
                <div>
                    <canvas ref={canvasRef} />
                </div>
            </>
        </div>
    );
}

export default PorcentagemCategoriasHistorico;
