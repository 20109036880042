import './DashboardSideBar.css'
import { useEffect, useState } from 'react';
import useWindowWidth from '../../../useWindowWidth';
import { useFinancialContext } from '../../../FinancialContext';
import OverlaySelection from './OverlaySelection';
import MoreOptionsSelection from './MoreOptionsSelection';
import AccountEdit from '../DashboardViews/ResumoView/ResumoViewCards/AccountsCard/AccountEdit';
import CreditCardInEdit from '../DashboardViews/ResumoView/ResumoViewCards/CreditCard/CreditCardInEdit';

function DashboardSideBar({ selectedView, handleViewClick, allViews, setOpenFormsCreateNewFinance, handleClickScrollHome,
    openFullCard, viewClicked, handleClickScrollEstatistics }) {

    const isMobile = useWindowWidth();
    const { handleOpenEditView } = useFinancialContext();

    const [newFinance, setNewFinance] = useState(false);
    const [overlayComponent, setOverlayComponent] = useState(null);


    const handleClickViewSideBar = (view) => {
        handleViewClick(view);
        setNewFinance(false);
        setOverlayComponent(null);
    }
    const handleClickNewFinance = (type) => {
        handleViewClick("Finanças");
        openFullCard(type)
        setTimeout(() => {
            setOpenFormsCreateNewFinance(true);
            setNewFinance(false);
            setOverlayComponent(null);
        }, 100)
    }
    const handleClickOverlay = (type, option) => {
        setOverlayComponent(type);
        setNewFinance(option)

        if (!option && overlayComponent) {
            setOverlayComponent(null);
            setNewFinance(false);
        }
    }
    const sideBarDesktop = (
        <>
            <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' onClick={() => handleClickViewSideBar("Finanças")} />
            <ul>
                <div>
                    {Object.values(allViews).map((view) => (
                        <li
                            key={view}
                            onClick={() => handleClickViewSideBar(view.title)}
                            className={`${selectedView === view.title ? 'active' : ''}`}
                        >
                            <img src={view.img} />
                            <p>{view.title}</p>
                        </li>
                    ))}
                </div>
            </ul>
        </>
    )
    const handleClickGoToAccounts = () => {
        handleOpenEditView(<AccountEdit />, "Suas contas já cadastradas");
    }
    const handleClickGoToCreditCard = () => {
        handleOpenEditView(<CreditCardInEdit />, "Seus cartões já criados");
    }





    const spendColor = '#ED4C5C';
    const incomeColor = '#489946';

    const valuesNewFinance = [
        (
            <div
                style={{
                    borderColor: incomeColor,
                    height: '13vw'
                }}
                onClick={() => handleClickNewFinance('income')}
            >
                <p style={{ color: incomeColor }}>Nova receita</p>

                <div style={{ backgroundColor: incomeColor }}>
                    <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg'
                        style={{
                            width: '40%'
                        }}
                    />
                </div>
            </div>
        ),
        (
            <div
                style={{
                    borderColor: spendColor,
                    height: '13vw'
                }}
                onClick={() => handleClickNewFinance('spend')}
            >
                <p style={{ color: spendColor }}>Novo gasto</p>

                <div style={{ backgroundColor: spendColor }}>
                    <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg'
                        style={{
                            width: '40%'
                        }}
                    />
                </div>
            </div>
        )
    ];
    const valuesSeeMore = [
        {
            onClick: () => {
                handleClickViewSideBar('Finanças')
                setTimeout(() => {
                    handleClickScrollHome(0);
                }, 50)
            },
            title: 'Home',
            img: '/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/homeBlackIcon.svg'
        },
        {
            onClick: () => {
                handleClickViewSideBar('Estatísticas')
                setTimeout(() => {
                    handleClickScrollEstatistics(0);
                }, 50)
            },
            title: 'Categorias',
            img: '/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/CategoriesBlackIcon.svg'
        },
        {
            onClick: () => {
                handleClickViewSideBar('Estatísticas');
                setTimeout(() => {
                    handleClickScrollEstatistics(750);
                }, 50)
            },
            title: 'Histórico',
            img: '/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/EstatisticasBlackIcon.svg'
        },
        {
            onClick: () => handleClickViewSideBar('Meu perfil'),
            title: 'Perfil',
            img: '/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ProfileEditBlackIcon.svg'
        },
        {
            onClick: () => handleClickViewSideBar('Suporte'),
            title: 'Suporte',
            img: '/Images_Dashboard_Summary/SummaryView/Header/messageBlackIcon.svg'
        }
    ];
    const buttons = (
        <ul>
            <li>
                <p>Ver mais</p>
                <div onClick={() => handleClickOverlay(valuesSeeMore, false)} >
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/seeMoreBlackIcon.svg' />
                </div>
            </li>
            <li onClick={handleClickGoToAccounts}>
                <p>Contas</p>
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/AccountsBlackIcon.svg' />
                </div>
            </li>
            <li onClick={handleClickGoToCreditCard}>
                <p>Cartões</p>
                <div>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/CreditCardBlackIcon.svg' />
                </div>
            </li>
            <li>
                <p>Criar</p>
                <div onClick={() => handleClickOverlay(valuesNewFinance, true)}>
                    <img src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/AddBlackIcon.svg' />
                </div>
            </li>
        </ul>
    )



    useEffect(() => {
        setNewFinance(false);
        setOverlayComponent(null);
    }, [viewClicked])

    return (
        <>
            {isMobile ?
                <>
                    {overlayComponent &&
                        (newFinance ? (
                            <OverlaySelection
                                setOverlayOn={setNewFinance}
                                values={overlayComponent}
                                color={'#CB2636'}
                                setOverLayComponent={setOverlayComponent}
                                maxHeight={'60vw'}
                            />
                        ) : (
                            <MoreOptionsSelection
                                values={overlayComponent}
                            />
                        ))}
                    <div className="containerBottomBarSmartphone">
                        {buttons}
                    </div>
                </>
                :
                <div className="containerSideBar">
                    {sideBarDesktop}
                </div >
            }
        </>
    )
}

export default DashboardSideBar;