import "./LoginSection.css"
import InputLoginSingIn from "../../Page 3 - NewUser/InputLoginSingIn";
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Link } from "react-router-dom";
import { useFinancialContext } from "../../../FinancialContext";
import GoogleLoginButton from "../../Page 3 - NewUser/FormsCreateNewUser/LoginGoogleButton";

const LoginSection = () => {
    const isMounted = useRef(false);
    const { axiosURL, checkTokenValidity, setUserLogged, fetchMainData } = useFinancialContext();

    const navigate = useNavigate();
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setEmailErrorMessage("É obrigatório informar o email para acessar sua conta.");
            return;
        }
        if (!password) {
            setPasswordErrorMessage("É obrigatório informar a senha para acessar a sua conta.");
            return;
        }

        const values = {
            email: email,
            senha: password
        }

        axios.post(`${axiosURL}/login`, values)
            .then(response => {
                if (response.status === 200 && response.data.token) {
                    const tokenJwt = response.data.token;
                    localStorage.setItem("SnnAcss", tokenJwt);
                    setUserLogged(true);
                    fetchMainData(tokenJwt);
                    navigate('/dashboard');
                    return;
                }
                setUserLogged(false);
            })
            .catch(error => {
                setUserLogged(false);
                if (error.response && error.response.status === 401) {
                    setLoginErrorMessage('E-mail ou senha informado está incorreto.');
                }
                else {
                    setLoginErrorMessage('Ocorreu um erro, mas já estamos trabalhando para resolver o mais rápido possível!');
                }
            })
    }


    useEffect(() => {
        setEmailErrorMessage('');
        setPasswordErrorMessage('');
    }, [email, password]);



    const userIsLogged = async () => {
        if (!isMounted.current) {
            isMounted.current = true;

            try {
                const userLogged = await checkTokenValidity();
                if (userLogged) {
                    setUserLogged(true);
                    navigate("/dashboard");
                    return;
                }
                setUserLogged(false);
                localStorage.removeItem("SnnAcss")
            } catch (error) {
                console.error('Usuário não logado.');
                setUserLogged(false);
                localStorage.removeItem("SnnAcss");
            }
        }
    };

    useEffect(() => {
        userIsLogged()
    }, [])


    return (
        <form className='LoginForms' onSubmit={handleLoginSubmit} onKeyDown={(e) => { if (e.key === 'Enter') handleLoginSubmit(e) }}>
            <header>
                <h1>Acesse seu Dashboard</h1>
                <p>Ainda não tem uma conta? <span onClick={() => navigate('/cadastrar')}>Cadastre-se aqui</span></p>
            </header>
            <section>
                <InputLoginSingIn
                    inputType={'email'}
                    textPlaceholder={"Digite o seu e-mail"}
                    moveTitle={"Login"}
                    errorMessage={emailErrorMessage}
                    valueInputed={email}
                    valueSetter={setEmail}
                    inputSize={70}
                />
                <InputLoginSingIn
                    inputType={'password'}
                    textPlaceholder={"Digite a sua senha"}
                    moveTitle={"Senha"}
                    errorMessage={passwordErrorMessage}
                    valueInputed={password}
                    valueSetter={setPassword}
                    inputSize={15}
                />
            </section>
            <footer>
                <div>
                    <GoogleLoginButton option={"LOGIN"} />
                    <button>Entrar via e-mail</button>
                </div>
                <Link to={'/esqueciSenha'}>Esqueci a senha</Link>
            </footer>
            <p className="errorMessageFormsLogin">{loginErrorMessage}</p>
        </form>
    )
}

export default LoginSection;