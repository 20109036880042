import './IncomeSpendSumarryCard.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../useWindowWidth';

function IncomeSpendSumarryCard({ type }) {
    const { totalIncome, totalIncomeReceivedLeft, totalSpendPaidLeft, totalSpend, hideFinances } = useFinancialContext();
    const isMobile = useWindowWidth();

    const title = type === 'income' ? 'Receitas totais' : 'Gastos totais';
    const text2 = type === 'income' ? 'A receber' : 'A pagar';

    const color = type === 'income' ? "#489946" : "#ED4C5C"

    const brazzilianPattern = (value) => {
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    const lineDesktop = (
        <div className='IncomeSpendSummaryContainer'>
            <header>
                <p>{title}</p>
                <p style={{ color: type === 'income' ? '#489946' : '#ED4C5C' }}>R$
                    <b>
                        {hideFinances ? "●●" :
                            type === 'income' ? ` ${brazzilianPattern(totalIncome)}` : ` ${brazzilianPattern(totalSpend)}`
                        }
                    </b>
                </p>
            </header>
            <body>
                <div>
                    <p>{text2}</p>
                    <p id='toPaiReceiveGrey'>R$
                        {hideFinances ? "●●" :
                            type === 'income' ? ` ${brazzilianPattern(totalIncomeReceivedLeft)}` : ` ${brazzilianPattern(totalSpendPaidLeft)}`
                        }
                    </p>
                </div>
            </body>
        </div>
    )

    const lineSmartphone = (
        <div className='IncomeSpendSummaryContainerSmartphone'>
            <div>
                <div style={{ backgroundColor: type === 'income' ? "#489946" : "#ED4C5C" }}>
                    <img src="Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg" />
                </div>
                <p>{title}</p>
            </div>
            <p style={{ color: color }}>R$
                <span>
                    {hideFinances ? "●●" :
                        type === 'income' ? ` ${brazzilianPattern(totalIncome)}` : ` ${brazzilianPattern(totalSpend)}`
                    }
                </span>
            </p>
        </div>
    )

    return isMobile ? lineSmartphone : lineDesktop;
}

export default IncomeSpendSumarryCard;